import React, { memo, useState } from 'react';

import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import PagSeguroConnectButton from './PagSeguroConnectButton';
import PixExpirationField from './PixExpirationField';

import util from '~/assets/util';

import { PagSeguro, PaymentPlatforms } from '~/assets/constants';

import styles from './index.module.scss';

interface PaymentSettingsProps {
  disabled: any;
  tenant: any;
  onFieldChange: any;
}

function PaymentSettings({ disabled, tenant, onFieldChange }: PaymentSettingsProps) {
  const [activeTab, setActiveTab] = useState(PaymentPlatforms.PAGSEGURO);

  const goToRedeTab = () => {
    setActiveTab(PaymentPlatforms.REDE);
  };

  const goToPagSeguroTab = () => {
    setActiveTab(PaymentPlatforms.PAGSEGURO);
  };

  return (
    <>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={`cursor-pointer ${activeTab === PaymentPlatforms.PAGSEGURO ? 'active' : ''}`}
            onClick={() => setActiveTab(PaymentPlatforms.PAGSEGURO)}>
            {util.t('PAGSEGURO')}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={`cursor-pointer ${activeTab === PaymentPlatforms.REDE ? 'active' : ''}`}
            onClick={goToRedeTab}>
            {util.t('REDE')}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane className="pt-2" tabId={PaymentPlatforms.PAGSEGURO}>
          <PagSeguroConnectButton disabled={disabled} className="my-2" />

          <Row form>
            <Col sm={6}>
              <FormGroup>
                <Label htmlFor="pagseguro_max_installments">{util.t('MAX_INSTALLMENTS')}</Label>
                <Input
                  className="form-control"
                  value={tenant.pagseguro_max_installments}
                  onChange={onFieldChange}
                  type="number"
                  name="pagseguro_max_installments"
                  id="pagseguro_max_installments"
                  min={1}
                  max={12}
                  required={tenant.card_platform == PaymentPlatforms.PAGSEGURO}
                  onInvalid={goToPagSeguroTab}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label htmlFor="pagseguro_installment_min_value">
                  {util.t('INSTALLMENT_MIN_VALUE')} (R$)
                </Label>
                <Input
                  className="form-control"
                  value={tenant.pagseguro_installment_min_value}
                  onChange={onFieldChange}
                  type="number"
                  name="pagseguro_installment_min_value"
                  id="pagseguro_installment_min_value"
                  min={0}
                  required={tenant.card_platform == PaymentPlatforms.PAGSEGURO}
                  onInvalid={goToPagSeguroTab}
                />
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Label htmlFor="pagseguro_interest_rate">{util.t('INTEREST_RATE')}(%)</Label>
                <Input
                  className="form-control"
                  value={tenant.pagseguro_interest_rate}
                  onChange={onFieldChange}
                  type="number"
                  name="pagseguro_interest_rate"
                  id="pagseguro_interest_rate"
                  required={tenant.card_platform == PaymentPlatforms.PAGSEGURO}
                  min={0}
                  step={0.01}
                  onInvalid={goToPagSeguroTab}
                />
              </FormGroup>
            </Col>

            <Col sm={6}>
              <FormGroup>
                <Label htmlFor="pagseguro_interest_from_installment">
                  {util.t('INSTALLMENT_INTEREST_FROM')}
                </Label>
                <Input
                  className="form-control"
                  value={tenant.pagseguro_interest_from_installment}
                  onChange={onFieldChange}
                  type="number"
                  name="pagseguro_interest_from_installment"
                  id="pagseguro_interest_from_installment"
                  min={1}
                  max={12}
                  required={tenant.card_platform == PaymentPlatforms.PAGSEGURO}
                  onInvalid={goToPagSeguroTab}
                />
              </FormGroup>
            </Col>
          </Row>

          {tenant.pagseguro_version == PagSeguro.V4 && (
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label>{util.t('PIX_CODE_VALIDITY')}</Label>

                  <PixExpirationField
                    className={styles.pixExpirationField}
                    initialValue={tenant.pagseguro_pix_validity}
                    setValue={(value) =>
                      onFieldChange({ target: { name: 'pagseguro_pix_validity', value } })
                    }
                    onInvalid={goToPagSeguroTab}
                  />
                </FormGroup>
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane className="pt-2" tabId={PaymentPlatforms.REDE}>
          <fieldset>
            <Row form>
              <Col xl={9}>
                <FormGroup>
                  <Label htmlFor="rede_token">{util.t('TOKEN')}</Label>
                  <Input
                    className="form-control"
                    value={tenant.rede_token}
                    onChange={onFieldChange}
                    type="text"
                    name="rede_token"
                    id="rede_token"
                    required={tenant.card_platform == PaymentPlatforms.REDE}
                    onInvalid={goToRedeTab}
                  />
                </FormGroup>
              </Col>
              <Col xl={3}>
                <FormGroup>
                  <Label htmlFor="rede_pv">{util.t('PV')}</Label>
                  <Input
                    className="form-control"
                    value={tenant.rede_pv}
                    onChange={onFieldChange}
                    type="text"
                    name="rede_pv"
                    id="rede_pv"
                    required={tenant.card_platform == PaymentPlatforms.REDE}
                    onInvalid={goToRedeTab}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col sm={6}>
                <FormGroup>
                  <Label htmlFor="rede_max_installments">{util.t('MAX_INSTALLMENTS')}</Label>
                  <Input
                    className="form-control"
                    value={tenant.rede_max_installments}
                    onChange={onFieldChange}
                    type="number"
                    name="rede_max_installments"
                    id="rede_max_installments"
                    min={1}
                    max={12}
                    required={tenant.card_platform == PaymentPlatforms.REDE}
                    onInvalid={goToRedeTab}
                  />
                </FormGroup>
              </Col>
              <Col sm={6}>
                <FormGroup>
                  <Label htmlFor="rede_installment_min_value">
                    {util.t('INSTALLMENT_MIN_VALUE')}
                  </Label>
                  <Input
                    className="form-control"
                    value={tenant.rede_installment_min_value}
                    onChange={onFieldChange}
                    type="number"
                    name="rede_installment_min_value"
                    id="rede_installment_min_value"
                    min={0}
                    required={tenant.card_platform == PaymentPlatforms.REDE}
                    onInvalid={goToRedeTab}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
        </TabPane>
      </TabContent>
    </>
  );
}

export default memo(PaymentSettings);
