import React, { memo } from 'react';

import MaskedInput from 'react-text-mask';

interface InputCellPhoneProps {
  name?: string;
  value: any;
  onChange: any;
  id?: string;
  className: string;
  required?: boolean;
  placeholder?: string;
}

const InputCellPhone = ({
  name,
  value,
  onChange,
  id,
  className,
  required = false,
  placeholder = '',
}: InputCellPhoneProps) => {
  return (
    <MaskedInput
      {...{ onChange, name, value, id, className, required, placeholder }}
      type="text"
      guide={false}
      mask={
        value.length > 14
          ? [
              '(',
              /[1-9]/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
          : [
              '(',
              /[1-9]/,
              /\d/,
              ')',
              ' ',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              '-',
              /\d/,
              /\d/,
              /\d/,
              /\d/,
              /\d/,
            ]
      }
      onBlur={onChange}
    />
  );
};

export default memo(InputCellPhone);
