import React, { memo, useState } from 'react';

import {
  Modal as BModal,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Row,
  Col,
} from 'reactstrap';

import Button from '~/components/Button';
import StatusSelect from '~/components/StatusSelect';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { OrderStatus } from '~/assets/constants';

const statusList = [
  ['STATUS_SHIPPED', OrderStatus.STATUS_SHIPPED],
  ['STATUS_READY_FOR_PICKUP', OrderStatus.STATUS_READY_FOR_PICKUP],
  ['STATUS_CANCELED', OrderStatus.STATUS_CANCELED],
  ['STATUS_REFUNDED', OrderStatus.STATUS_REFUNDED],
  ['STATUS_COMPLETED', OrderStatus.STATUS_COMPLETED],
  ['STATUS_CONFIRMED_PAYMENT', OrderStatus.STATUS_CONFIRMED_PAYMENT],
];

interface ChangeOrderStatusModalProps {
  storeOrder: any;
  onSave?: Function;
  toggle: Function;
  [key: string]: any;
}

function ChangeOrderStatusModal({
  storeOrder,
  onSave = null,
  toggle,
  ...props
}: ChangeOrderStatusModalProps) {
  const [form, setForm] = useState({
    status: 0,
    note: '',
  });

  const [loading, setLoading] = useState(false);

  const status = form.status;

  const doUpdate = async (ev, confirmedWarning = false) => {
    ev.preventDefault();

    if (loading) {
      return;
    }

    if (!confirmedWarning && showWarning(ev)) {
      return false;
    }

    setLoading(true);

    const url = `admin/orders/status/${storeOrder.store_order_id}`;

    try {
      await api.put(url, form);

      notification.$s(util.t('ORDER_STATUS_CHANGE_SUCCESS'));

      const updatedOrder = { ...storeOrder, status };

      onSave && onSave(updatedOrder);

      toggle();
    } catch (e) {
      notification.$e(e, false, false, 'center');

      console.error('ChangeOrderStatusModal.doUpdate', e);
    } finally {
      setLoading(false);
    }
  };

  const showWarning = (ev) => {
    if (!storeOrder.single_order && status == OrderStatus.STATUS_CONFIRMED_PAYMENT) {
      return notification.$w(
        util.t('STORE_ORDER_STATUS_WARNING'),
        (result) => result.isConfirmed && doUpdate(ev, true),
      );
    } else if (status == OrderStatus.STATUS_REFUNDED) {
      return notification.$w(
        util.t('REFUND_ORDER_ADVICE'),
        (result) => result.isConfirmed && doUpdate(ev, true),
      );
    }

    return false;
  };

  return (
    <BModal unmountOnClose size="lg" {...props}>
      <Card>
        <CardHeader>{util.t('CHANGE_STATUS')}</CardHeader>
        <CardBody>
          <form onSubmit={doUpdate} className="container-fluid p-3">
            <Row form>
              <Col lg="4">
                <FormGroup>
                  <Label htmlFor="status">{util.t('STATUS')} (*)</Label>

                  <StatusSelect
                    status={statusList}
                    onChange={(ev) => setForm({ ...form, status: ev.target.value })}
                    value={status}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col>
                <FormGroup>
                  <Label htmlFor="note">{util.t('NOTE')} (*)</Label>

                  <textarea
                    className="form-control"
                    name="note"
                    id="note"
                    onChange={(ev) => setForm({ ...form, note: ev.target.value })}
                    value={form.note}
                    required></textarea>
                </FormGroup>
              </Col>
            </Row>

            <Row form className="mt-3">
              <Col>
                <Button loading={loading} type="submit" color="primary">
                  {util.t('SAVE')}
                </Button>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(ChangeOrderStatusModal);
