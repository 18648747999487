import Swal from 'sweetalert2';

import util from '~/assets/util';

const notification = {
  $s: function (text, title = null, modal = false, toast = true, position = 'bottom-end') {
    if (modal) {
      this.notify(text, title, 'success', false, 'center');
    } else {
      this.notify(text, title, 'success', toast, position);
    }
  },
  $e: function (error, title = false, toast = true, position = 'bottom-end') {
    var message = '';

    if (typeof error == 'string') {
      message = error;
    } else if (error.response) {
      const response = error.response;

      const data = response.data;

      if (response.status == 401) {
        message = util.t('TOKEN_HAS_EXPIRED');
      } else if (data.errors) {
        Object.values(data.errors).forEach((fieldErrors) => {
          Object.values(fieldErrors).forEach((errorMessage) => {
            message += `${util.t(errorMessage)} <br/>`;
          });
        });
      } else if (data.message) {
        message = util.t(data.message);
      }
    } else if (error == null) {
      message = util.t('GENERIC_ERROR');
    }

    this.notify(message, title, 'error', toast, position);
  },

  $i: function (title, text, timer = 5000, showConfirmButton) {
    return this.notify(text, title, 'info', false, 'center', timer, showConfirmButton);
  },

  $w: function (message, callback, title = null) {
    return this.notify(
      title || util.t('WARNING'),
      message,
      'warning',
      false,
      'center',
      0,
      true,
      true,
    ).then(callback);
  },

  notify: function (
    title,
    text,
    icon,
    toast = true,
    position = 'bottom-end',
    timer = 5000,
    showConfirmButton = false,
    showCancelButton = false,
  ) {
    return Swal.fire({
      title,
      html: text,
      icon,
      toast,
      timer,
      position,
      showConfirmButton,
      showCancelButton,
    });
  },
};

export default notification;
