import React, { memo } from 'react';

import util from '~/assets/util';

import './index.scss';

interface ProductAvailabilityBadgeProps {
  active: any;
  hasStock: any;
  outOfCatalogue: any;
  className?: string;
}

function ProductAvailabilityBadge({
  active,
  hasStock,
  outOfCatalogue,
  className = '',
}: ProductAvailabilityBadgeProps) {
  if (!active) {
    return <span className={`badge badge danger ${className}`}>{util.t('INACTIVE_PRODUCT')}</span>;
  }

  if (!hasStock) {
    return <span className={`badge badge-warning ${className}`}>{util.t('OUT_OF_STOCK')}</span>;
  }

  if (outOfCatalogue) {
    return <span className={`badge badge-warning ${className}`}>{util.t('OUT_OF_CATALOGUE')}</span>;
  }

  return <></>;
}

export default memo(ProductAvailabilityBadge);
