import React, { memo } from 'react';

import Button from '../Button';

import util from '~/assets/util';

interface FormButtonsProps {
  isEditing: any;
  isSaving: any;
  onCancel: Function;
  onEdit?: Function | boolean;
}

function FormButtons({ isEditing, isSaving, onCancel, onEdit }: FormButtonsProps) {
  if (isEditing) {
    return (
      <Button color="info" type="button" onClick={onEdit}>
        {util.t('EDIT')}
      </Button>
    );
  }

  return (
    <>
      <Button color="danger" className="mr-1" type="button" onClick={onCancel}>
        {util.t('CANCEL')}
      </Button>

      <Button color="primary" type="submit" loading={isSaving}>
        {util.t('SAVE')}
      </Button>
    </>
  );
}

export default memo(FormButtons);
