import { memo } from 'react';

import util from '~/assets/util';

function TotalSelectedItems({ total }: any) {
  if (total == 0) {
    return util.t('NO_ITEMS_SELECTED');
  }

  if (total == 1) {
    return util.t('ONE_ITEM_SELECTED');
  }

  return util.t('TOTAL_SELECTED_ITEMS').replace('{total}', total);
}

export default memo(TotalSelectedItems);
