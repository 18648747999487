import React, { memo, useRef, useState, useEffect } from 'react';

import { FormGroup, Row, Col, Label, Input, Spinner } from 'reactstrap';

import FormButtons from '~/components/FormButtons';
import SearchCategoryInput from '~/components/Category/SearchCategoryInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';
import { MenuType } from '~/assets/constants';

import './index.scss';

interface FormProps {
  id?: any;
  onClose?: Function;
  onSave?: Function;
  defaultPosition?: any;
}

function Form({ id, onClose, onSave, defaultPosition = 1 }: FormProps) {
  const [saving, setSaving] = useState(false);
  const [category, setCategory] = useState({
    tenant_category_id: 0,
    category_id: null,
    url: null,
    tenant_id: 0,
    name: '',
    label: '',
    position: defaultPosition,
    active: true,
  });

  const [type, setType] = useState(MenuType.CATEGORY);

  const [loading, setLoading] = useState(false);

  const selectRef = useRef(null);
  const urlInputRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setCategory({ ...category, [name]: ev.target.value });
  };

  const handleChangeSelect = (option) => {
    option && setCategory({ ...category, ...option });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    if (type == MenuType.CATEGORY && !category.category_id) {
      return notification.$e(util.t('SELECT_A_CATEGORY'));
    }

    let form = {};
    if (type == MenuType.CATEGORY) {
      form = { ...category, url: '', label: '' };
    } else {
      form = { ...category, category_id: null };
    }

    setSaving(true);

    try {
      const res = id
        ? await api.put(`admin/menu-categories/${id}`, form)
        : await api.post(`admin/menu-categories/create`, form);

      const data = res?.data;

      if (data) {
        onSave();
        onClose();

        notification.$s(util.t('CATEGORY_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    async function loadCategory() {
      setLoading(true);

      try {
        const res = await api.get(`admin/menu-categories/${id}`);

        const data = res?.data;

        const category = data?.category;

        if (category.url) {
          setType(MenuType.LINK);
        }

        setCategory(category);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadCategory();
    } else {
      selectRef.current?.focus();
    }
  }, []);

  useEffect(() => {
    if (type == MenuType.CATEGORY) {
      selectRef.current?.focus();
    } else {
      urlInputRef.current?.focus();
    }
  }, [type]);

  if (loading) {
    return (
      <div className="p-5">
        <Spinner size="lg" className="d-block m-auto" color="primary" />
      </div>
    );
  }

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        <>
          <fieldset>
            <Row className="mb-3" form>
              <Col>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      name="type"
                      type="radio"
                      value={MenuType.CATEGORY}
                      checked={type == MenuType.CATEGORY}
                      onChange={() => setType(MenuType.CATEGORY)}
                    />
                    {util.t('CATEGORY')}
                  </Label>
                </FormGroup>
                <FormGroup check inline>
                  <Label check>
                    <Input
                      name="type"
                      type="radio"
                      value={MenuType.LINK}
                      checked={type == MenuType.LINK}
                      onChange={() => setType(MenuType.LINK)}
                    />
                    {util.t('LINK')}
                  </Label>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              {type == MenuType.CATEGORY ? (
                <Col md="6" xl="6">
                  <Label htmlFor="name">{util.t('CATEGORY')}</Label>

                  <SearchCategoryInput
                    onChange={handleChangeSelect}
                    setRef={(ref) => (selectRef.current = ref)}
                    value={category}
                  />
                </Col>
              ) : (
                <>
                  <Col md="6" xl="4">
                    <FormGroup>
                      <Label htmlFor="link">{util.t('LINK')}</Label>
                      <Input
                        type="url"
                        value={category.url || ''}
                        name="url"
                        id="url"
                        onChange={handleChange}
                        innerRef={urlInputRef}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col md="6" xl="4">
                    <FormGroup>
                      <Label htmlFor="link">{util.t('TITLE')}</Label>
                      <Input
                        type="text"
                        value={category.label || ''}
                        name="label"
                        id="label"
                        onChange={handleChange}
                        required
                      />
                    </FormGroup>
                  </Col>
                </>
              )}

              <Col md="1">
                <FormGroup>
                  <Label htmlFor="link">{util.t('POSITION')}</Label>
                  <Input
                    type="number"
                    min="1"
                    max="7"
                    name="position"
                    id="position"
                    required
                    value={category.position}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <FormButtons
                  isEditing={false}
                  isSaving={saving}
                  onCancel={() => onClose()}
                  onEdit={() => {}}
                />
              </footer>
            </Col>
          </Row>
        </>
      </form>
    </>
  );
}

export default memo(Form);
