import React, { useState, useRef, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FaLock, FaUserCircle } from 'react-icons/fa';

import Button from '~/components/Button';

import util from '~/assets/util';
import userService from '~/services/user';
import tenantService from '~/services/tenant';

import './index.scss';

const Login = () => {
  const navigate = useNavigate();

  const user = useSelector((state: any) => state.user);

  const [loading, setLoading] = useState(false);

  const emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    if (user.data) {
      navigate('/');
    }
  }, []);

  const handleLogin = async (ev) => {
    ev.preventDefault();

    if (loading) {
      return false;
    }

    setLoading(true);

    const email = emailRef.current?.value;
    const password = passwordRef.current?.value;

    const user = await userService.login(email, password);

    if (user) {
      const tenant = await tenantService.get();

      if (tenant) {
        tenantService.save(tenant);
      }

      return navigate('/');
    }

    setLoading(false);
  };

  return (
    <>
      <div className="box-login">
        <div className="col-10 col-md-4">
          <div className="box-login-content">
            <div>
              <form onSubmit={handleLogin}>
                <div className="col-12 form-group">
                  <div className="box-login-input">
                    <FaUserCircle />

                    <input
                      type="email"
                      name="email"
                      ref={emailRef}
                      placeholder={util.t('EMAIL')}
                      autoComplete="email"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 form-group">
                  <div className="box-login-input">
                    <FaLock />

                    <input
                      type="password"
                      name="password"
                      ref={passwordRef}
                      placeholder={util.t('PASSWORD')}
                      autoComplete="current-password"
                      required
                    />
                  </div>
                </div>
                <div className="col-12 mb-2 text-center">
                  <a href="#">{util.t('FORGOT_PASSWORD')}</a>
                </div>
                <div className="col-12 text-center">
                  <Button className="btn-submit" type="submit" color="primary" loading={loading}>
                    {util.t('LOGIN')}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
