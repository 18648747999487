import React, { memo } from 'react';

import NumberFormat from 'react-number-format';

import { Input } from 'reactstrap';

interface AttributeInputProps {
  attribute: any;
  [key: string]: any;
}

function AttributeInput({ attribute, ...props }: AttributeInputProps) {
  if (attribute.type == 'decimal') {
    return (
      <NumberFormat
        className="form-control"
        value={attribute?.value}
        decimalSeparator=","
        allowNegative={false}
        decimalScale={3}
        {...props}
      />
    );
  }

  return <Input type={attribute.type} value={attribute?.value} {...props} />;
}

export default memo(AttributeInput);
