import React, { memo, useEffect, useRef, useState } from 'react';

import {
  Alert,
  Col,
  Collapse,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  Spinner,
} from 'reactstrap';

import { format, parseISO } from 'date-fns';

import CurrencyInput from 'react-currency-masked-input';

import GeneratorFields from './GeneratorFields';

import FormButtons from '~/components/FormButtons';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import { Chars, CouponCodeTypes, CouponType } from '~/assets/constants';

import './index.scss';

interface FormProps {
  onSave: Function;
  onClose: Function;
  id?: any;
  withGenerator?: any;
}

function Form({ onSave, onClose, id = 0, withGenerator = false }: FormProps) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);

  const [coupon, setCoupon]: any = useState({
    code: '',
    type: CouponType.FREE_SHIPPING,
    active: true,
    expires_at: '',
    expires_at_time: '',
    expires_at_date: '',
    usage_limit: '',
    user_usage_limit: '',
    min_value: '',
    code_type: CouponCodeTypes.NUMERIC,
    qty: '',
    code_length: 5,
  });

  const inputCodeRef = useRef(null);
  const inputValueRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setCoupon({ ...coupon, [name]: ev.target.value || null });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    if (coupon.qty && !validateCouponQty()) {
      setSaving(false);

      return;
    }

    let form = coupon;

    if (form.expires_at_date) {
      form.expires_at = `${coupon.expires_at_date}`;

      if (form.expires_at_time) {
        form.expires_at += ` ${coupon.expires_at_time}`;
      }
    }

    try {
      const res = id
        ? await api.put(`admin/coupons/${id}`, form)
        : await api.post('admin/coupons', form);

      const data = res?.data;

      if (withGenerator && data.result) {
        notification.$s(util.t('COUPONS_GENERATED'));
      } else if (data.coupon) {
        notification.$s(util.t('COUPON_SAVED'));
      }

      onSave();
      onClose();
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const validateCouponQty = () => {
    const qty = coupon.qty;
    const codeLength = coupon.code_length;

    const minLengthNum = Math.ceil(Math.log(qty) / Math.log(CouponCodeTypes.NUMERIC.length));
    const minLengthAlpha = Math.ceil(Math.log(qty) / Math.log(Chars.ALPHANUMERIC.length));

    const minimumChars =
      coupon.code_type == CouponCodeTypes.NUMERIC ? minLengthNum : minLengthAlpha;

    if (codeLength >= minimumChars) {
      return true;
    }

    const message =
      util.t('CODE_LENGTH_TOO_SHORT') +
      ' ' +
      util
        .t('CODE_LENGTH_SUGGESTION')
        .replace('%qty%', qty)
        .replace('%num%', minLengthNum)
        .replace('%alpha%', minLengthAlpha);

    notification.$i(message, '', 0);

    return false;
  };

  const cancelEdit = () => {
    if (id === 0) {
      return onClose();
    }

    setEditing(true);
  };

  useEffect(() => {
    async function loadCoupon() {
      setLoading(true);

      try {
        const res = await api.get(`admin/coupons/${id}`);

        const data = res?.data;

        const coupon = data?.coupon;

        if (coupon) {
          const expiresAt = parseISO(coupon.expires_at);

          setCoupon({
            ...coupon,
            expires_at_date: coupon.expires_at ? format(expiresAt, `Y-MM-dd`) : '',
            expires_at_time: coupon.expires_at ? format(expiresAt, `HH:mm`) : '',
          });
        }
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      setEditing(true);

      loadCoupon();
    }
  }, []);

  useEffect(() => {
    !editing && inputCodeRef?.current?.focus();
  }, [editing]);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset disabled={editing}>
              <Row form>
                <Col sm="6" md="4" lg="3" xl="3">
                  <FormGroup>
                    <Label htmlFor="name">{util.t('CODE')}</Label>
                    <Input
                      value={coupon.code}
                      onChange={handleChange}
                      type="text"
                      name="code"
                      id="code"
                      maxLength={255}
                      required
                      autoFocus
                      innerRef={inputCodeRef}
                    />
                  </FormGroup>
                </Col>
                <Col sm="6" md="8" lg="5" xl="3">
                  <FormGroup>
                    <Label htmlFor="expires_at">{util.t('EXPIRATION_DATE')}</Label>
                    <InputGroup>
                      <Input
                        value={coupon.expires_at_date}
                        onChange={handleChange}
                        type="date"
                        name="expires_at_date"
                        id="expires_at_date"
                      />
                      <InputGroupAddon addonType="append">
                        <Input
                          value={coupon.expires_at_time}
                          onChange={handleChange}
                          type="time"
                          name="expires_at_time"
                          id="expires_at_time"
                        />
                      </InputGroupAddon>
                    </InputGroup>
                    {(coupon.expires_at_date || coupon.expires_at_time) && (
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => {
                          setCoupon({ ...coupon, expires_at_time: '', expires_at_date: '' });
                        }}
                        role="button">
                        {util.t('REMOVE')}
                      </span>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="4" lg="3" xl="2">
                  <FormGroup>
                    <Label htmlFor="min_value">{util.t('MIN_VALUE')}</Label>
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>R$</InputGroupText>
                      </InputGroupAddon>
                      <CurrencyInput
                        className="form-control"
                        value={coupon.min_value + ''}
                        onChange={(ev, maskedValue) => {
                          setCoupon({ ...coupon, min_value: maskedValue });
                        }}
                        name="min_value"
                        id="min_value"
                        placeholder="-"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <Row classname="mb-2" form>
                <Col lg="4" xl="3">
                  <FormGroup>
                    <Label htmlFor="usage_limit">{util.t('ORDER_USAGE_LIMIT')}</Label>
                    <Input
                      placeholder={util.t('UNLIMITED')}
                      value={coupon.usage_limit}
                      onChange={handleChange}
                      type="number"
                      name="usage_limit"
                      id="usage_limit"
                      min={1}
                      pattern="\d*"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" xl="3">
                  <FormGroup>
                    <Label htmlFor="user_usage_limit">{util.t('USER_USAGE_LIMIT')}</Label>
                    <Input
                      placeholder={util.t('UNLIMITED')}
                      value={coupon.user_usage_limit}
                      onChange={handleChange}
                      type="number"
                      name="user_usage_limit"
                      id="user_usage_limit"
                      min={1}
                      pattern="\d*"
                    />
                  </FormGroup>
                </Col>
                <Col lg="4" xl="3">
                  <FormGroup>
                    <Label htmlFor="active">{util.t('STATUS')}</Label>

                    <StatusSwitch
                      disabled={editing}
                      onChange={(value) => setCoupon({ ...coupon, active: value })}
                      checked={!!coupon.active}
                      name="active"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mb-2" form>
                <Col>
                  <Label htmlFor="type">{util.t('DISCOUNT_TYPE')}</Label>
                  <br />
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        name="type"
                        type="radio"
                        value={CouponType.FREE_SHIPPING}
                        checked={coupon.type == CouponType.FREE_SHIPPING}
                        onChange={() => setCoupon({ ...coupon, type: CouponType.FREE_SHIPPING })}
                      />
                      {util.t('FREE_SHIPPING')}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        name="type"
                        type="radio"
                        checked={coupon.type == CouponType.PERCENT_DISCOUNT}
                        value={CouponType.PERCENT_DISCOUNT}
                        onChange={() => setCoupon({ ...coupon, type: CouponType.PERCENT_DISCOUNT })}
                      />
                      {util.t('PERCENT')}
                    </Label>
                  </FormGroup>
                  <FormGroup check inline>
                    <Label check>
                      <Input
                        name="type"
                        type="radio"
                        checked={coupon.type == CouponType.CASH}
                        value={CouponType.CASH}
                        onChange={() => setCoupon({ ...coupon, type: CouponType.CASH })}
                      />
                      {util.t('VOUCHER')}
                    </Label>
                  </FormGroup>
                </Col>
              </Row>

              <Collapse
                isOpen={coupon.type == CouponType.PERCENT_DISCOUNT}
                onEntered={() => {
                  inputValueRef.current?.focus();
                }}>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="value">{util.t('VALUE')}</Label>
                      <InputGroup>
                        {coupon.type == CouponType.PERCENT_DISCOUNT && (
                          <Input
                            min="1"
                            max="100"
                            className="form-control"
                            value={coupon.value || ''}
                            onChange={handleChange}
                            type="number"
                            name="value"
                            id="value"
                            innerRef={inputValueRef}
                            required={coupon.type == CouponType.PERCENT_DISCOUNT}
                          />
                        )}
                        <InputGroupAddon addonType="append">
                          <InputGroupText>%</InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>

              <Collapse isOpen={coupon.type == CouponType.CASH}>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="cash_value">{util.t('VALUE')}</Label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>R$</InputGroupText>
                        </InputGroupAddon>
                        <CurrencyInput
                          className="form-control"
                          value={coupon.value + ''}
                          onChange={(ev, maskedValue) => {
                            setCoupon({ ...coupon, value: maskedValue });
                          }}
                          name="value"
                          id="cash_value"
                          placeholder="-"
                          required={coupon.type == CouponType.CASH}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
              </Collapse>
            </fieldset>

            {withGenerator && (
              <>
                <hr />

                <Alert isOpen={!coupon.code} color="info">
                  {util.t('FILL_COUPON_CODE_TO_GENERATE_COUPONS')}
                </Alert>

                <fieldset disabled={!coupon.code}>
                  <GeneratorFields {...{ coupon, setCoupon, handleChange }} />
                </fieldset>
              </>
            )}

            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
