import React, { useState, useEffect, memo } from 'react';

import Select from 'react-select';

import util from '~/assets/util';

import api from '~/services/api';

interface ShippingMethodSelectProps {
  defaultSelected?: any[];
  onChange: Function;
  id?: any;
}

function ShippingMethodSelect({
  defaultSelected = [],
  onChange,
  id = 'shipping_method_id',
}: ShippingMethodSelectProps) {
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [availableMethods, setAvailableMethods] = useState([]);

  const doChange = (options) => {
    setSelected(options);

    onChange(options.map((option) => option.value));
  };

  useEffect(() => {
    async function load() {
      try {
        const res = await api.get('admin/shipping-methods');

        const { methods } = res.data;

        const options = methods.map((method) => ({
          label: method.name,
          value: method.shipping_method_id,
        }));

        setAvailableMethods(options);

        setSelected(options.filter((option) => defaultSelected.indexOf(option.value) != -1));
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    load();
  }, []);

  return (
    <Select
      id={id}
      name="shipping_method_id"
      isMulti
      required
      value={selected}
      options={availableMethods}
      isLoading={loading}
      backspaceRemovesValue={false}
      placeholder={util.t('ADD')}
      loadingMessage={() => util.t('LOADING_THREE_DOTS')}
      noOptionsMessage={() => util.t('NO_OPTION')}
      onChange={doChange}
    />
  );
}

export default memo(ShippingMethodSelect);
