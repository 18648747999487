import React, { memo, useEffect, useRef, useState } from 'react';

import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Spinner,
} from 'reactstrap';
import classnames from 'classnames';

import FormButtons from '~/components/FormButtons';
import InputCellPhone from '~/components/InputCellPhone';
import InputCnpj from '~/components/InputCnpj';
import InputZipCode from '~/components/InputZipCode';
import StateSelect from '~/components/StateSelect';
import ImageInput from '~/components/ImageInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

function Form() {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [editing, setEditing] = useState(false);
  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [tenant, setTenant] = useState({
    cnpj: '',
    company_name: '',
    phone: '',
    whatsapp: '',
    email: '',
    facebook: '',
    instagram: '',
    description: '',
    logo: '',
    logo_url: '',
    logo_footer_url: '',
    favicon_url: '',
    file: '',
    footer_file: '',
    favicon_file: '',
    opening_hours: '',
    street: '',
    number: '',
    city: '',
    state: '',
    zipcode: '',
  });

  const inputEmailRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setTenant({ ...tenant, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      let formData = new FormData();

      if (tenant.file) {
        formData.append('logo', tenant.file);
      }

      if (tenant.footer_file) {
        formData.append('footer_logo', tenant.footer_file);
      }

      if (tenant.favicon_file) {
        formData.append('favicon', tenant.favicon_file);
      }

      formData.append('email', tenant.email);
      formData.append('phone', tenant.phone);
      formData.append('whatsapp', tenant.whatsapp || '');
      formData.append('facebook', tenant.facebook || '');
      formData.append('instagram', tenant.instagram || '');
      formData.append('street', tenant.street || '');
      formData.append('number', tenant.number || '');
      formData.append('state', tenant.state || '');
      formData.append('city', tenant.city || '');
      formData.append('zipcode', tenant.zipcode || '');
      formData.append('cnpj', tenant.cnpj);
      formData.append('company_name', tenant.company_name);
      formData.append('description', tenant.description || '');
      formData.append('opening_hours', tenant.opening_hours || '');

      const url = `admin/tenants/update/details`;

      const res = await api.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      const data = res?.data;

      if (data.tenant_detail_id) {
        setEditing(true);

        notification.$s(util.t('GENERAL_INFORMATION_SAVED'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    setEditing(true);
  };

  useEffect(() => {
    async function loadGeneral() {
      setLoading(true);

      try {
        const res = await api.get('admin/tenants');

        let { details, logo_url, logo_footer_url, favicon_url } = res?.data;

        setTenant({ ...details, logo_url, logo_footer_url, favicon_url });
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    setEditing(true);
    loadGeneral();
  }, []);

  const handleOnChangeImage = (url, file, type) => {
    if (editing) {
      setEditing(false);
    }

    const updatedTenant = { ...tenant };
    if (type === 'logo') {
      updatedTenant.logo_url = url;
      updatedTenant.file = file;
    } else if (type === 'footer') {
      updatedTenant.logo_footer_url = url;
      updatedTenant.footer_file = file;
    } else if (type === 'favicon') {
      updatedTenant.favicon_url = url;
      updatedTenant.favicon_file = file;
    }

    setTenant(updatedTenant);
  };

  useEffect(() => {
    !editing && inputEmailRef?.current?.focus();
  }, [editing]);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset disabled={editing}>
              <Row form>
                <Col md="6">
                  <Row form>
                    <Col md="8">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === '1',
                              'disabled-tab': editing,
                            })} tab`}
                            onClick={() => {
                              toggle('1');
                            }}
                            style={{ cursor: 'pointer', color: editing ? '#B6B6B6' : null }}>
                            Header
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === '2',
                              'disabled-tab': editing,
                            })} tab`}
                            onClick={() => {
                              toggle('2');
                            }}
                            style={{ cursor: 'pointer', color: editing ? '#B6B6B6' : null }}>
                            Footer
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={`${classnames({
                              active: activeTab === '3',
                              'disabled-tab': editing,
                            })} tab`}
                            onClick={() => {
                              toggle('3');
                            }}
                            style={{ cursor: 'pointer', color: editing ? '#B6B6B6' : null }}>
                            FavIcon
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent activeTab={activeTab}>
                        <TabPane tabId="1">
                          <FormGroup>
                            <ImageInput
                              className="mb-3"
                              src={tenant.logo_url}
                              id="logo_url"
                              name="logo_url"
                              onChange={(url, file) => handleOnChangeImage(url, file, 'logo')}
                              disabled={editing}
                              alt="Logo"
                            />
                          </FormGroup>
                        </TabPane>
                        <TabPane tabId="2">
                          <FormGroup>
                            <ImageInput
                              className="mb-3"
                              src={tenant.logo_footer_url}
                              id="logo_footer_url"
                              name="logo_footer_url"
                              onChange={(url, file) => handleOnChangeImage(url, file, 'footer')}
                              disabled={editing}
                              alt="footer_icon"
                            />
                          </FormGroup>
                        </TabPane>
                        <TabPane tabId="3">
                          <FormGroup>
                            <ImageInput
                              className="mb-3"
                              src={tenant.favicon_url}
                              id="favicon_url"
                              name="favicon_url"
                              onChange={(url, file) => handleOnChangeImage(url, file, 'favicon')}
                              disabled={editing}
                              alt="favicon"
                            />
                          </FormGroup>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="email">{util.t('CONTACT_MAIL')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.email}
                          onChange={handleChange}
                          type="text"
                          name="email"
                          id="email"
                          required
                          innerRef={inputEmailRef}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="phone">{util.t('CELL_PHONE')}</Label>
                        <InputCellPhone
                          className="form-control"
                          value={tenant.phone || ''}
                          name="phone"
                          id="phone"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="whatsapp">{util.t('WHATSAPP')}</Label>
                        <InputCellPhone
                          className="form-control"
                          value={tenant.whatsapp || ''}
                          name="whatsapp"
                          id="whatsapp"
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="facebook">{util.t('URL_FACEBOOK')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.facebook || ''}
                          onChange={handleChange}
                          type="text"
                          name="facebook"
                          id="facebook"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="instagram">{util.t('URL_INSTAGRAM')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.instagram || ''}
                          onChange={handleChange}
                          type="text"
                          name="instagram"
                          id="instagram"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                <Col md="6">
                  <Row form>
                    <Col xl="4">
                      <FormGroup>
                        <Label htmlFor="cnpj">{util.t('LABEL_CNPJ')}</Label>
                        <InputCnpj
                          className="form-control"
                          value={tenant.cnpj}
                          id="cnpj"
                          name="cnpj"
                          required
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col xl="4">
                      <FormGroup>
                        <Label htmlFor="company_name">{util.t('COMPANY_NAME')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.company_name}
                          id="company_name"
                          name="company_name"
                          required
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="description">{util.t('DESCRIPTION')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.description || ''}
                          onChange={handleChange}
                          type="textarea"
                          name="description"
                          id="description"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="8">
                      <FormGroup>
                        <Label htmlFor="opening_hours">{util.t('OPENING_HOURS')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.opening_hours || ''}
                          onChange={handleChange}
                          type="textarea"
                          name="opening_hours"
                          id="opening_hours"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="6">
                      <FormGroup>
                        <Label htmlFor="street">{util.t('ADDRESS_STREET')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.street || ''}
                          onChange={handleChange}
                          type="text"
                          name="street"
                          id="street"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <Label htmlFor="number">{util.t('ADDRESS_NUMBER')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.number || ''}
                          onChange={handleChange}
                          type="text"
                          name="number"
                          id="number"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="state">{util.t('ADDRESS_STATE')}</Label>
                        <StateSelect
                          id="state"
                          name="state"
                          value={tenant.state || ''}
                          onChange={handleChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="city">{util.t('ADDRESS_CITY')}</Label>
                        <Input
                          className="form-control"
                          value={tenant.city || ''}
                          onChange={handleChange}
                          type="text"
                          name="city"
                          id="city"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col md="4">
                      <FormGroup>
                        <Label htmlFor="zipcode">{util.t('ADDRESS_ZIPCODE')}</Label>
                        <InputZipCode
                          className="form-control"
                          value={tenant.zipcode}
                          onChange={handleChange}
                          name="zipcode"
                          id="zipcode"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </fieldset>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <FormButtons
                    isEditing={editing}
                    isSaving={saving}
                    onCancel={cancelEdit}
                    onEdit={() => {
                      setEditing(false);
                    }}
                  />
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
