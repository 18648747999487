import React, { memo, useState, useEffect, useRef } from 'react';

import {
  Col,
  Modal,
  Card,
  CardHeader,
  CardBody,
  Form,
  Row,
  Label,
  FormGroup,
  Input,
  Spinner,
} from 'reactstrap';

import FormButtons from '~/components/FormButtons';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

const DEFAULT_STATE = {
  brand_id: 0,
  name: '',
};

interface BrandModalProps {
  isOpen?: any;
  id: any;
  onSave: Function;
  onToggle: any;
}

function BrandModal({ isOpen = false, id, onSave, onToggle }: BrandModalProps) {
  const [loading, setLoading] = useState(!!id);

  const [editing, setEditing] = useState(!!id);
  const [brand, setBrand] = useState(DEFAULT_STATE);

  const inputNameRef = useRef(null);

  const handleChange = (ev) => {
    const name = ev.target.name;

    setBrand({ ...brand, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();
    ev.stopPropagation();

    if (loading) {
      return;
    }

    setLoading(true);

    const url = 'admin/brands';

    try {
      const { data } = id ? await api.put(`${url}/${id}`, brand) : await api.post(url, brand);

      notification.$s(util.t('BRAND_SAVED'));

      setBrand(DEFAULT_STATE);

      onSave(data?.brand);
    } catch (e) {
      console.error('brandModal.doSave', e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  async function loadBrand(id) {
    try {
      const { data } = await api.get(`admin/brands/${id}`);

      const brand = data?.brand;

      setBrand(brand);
    } catch (e) {
      notification.$e(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!editing) {
      inputNameRef.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    id && loadBrand(id);
  }, []);

  return (
    <Modal autoFocus={false} isOpen={isOpen} toggle={onToggle}>
      <Card>
        <CardHeader>{util.t('BRAND')}</CardHeader>
        <CardBody>
          {loading ? (
            <Spinner color="primary" className="d-block m-auto" />
          ) : (
            <Form onSubmit={doSave}>
              <fieldset disabled={editing}>
                <Row form>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="name">
                        {util.t('NAME')} <strong>*</strong>
                      </Label>

                      <Input
                        autoFocus
                        id="name"
                        name="name"
                        value={brand.name}
                        onChange={handleChange}
                        innerRef={inputNameRef}
                        max="255"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </fieldset>
              <FormButtons
                isEditing={editing}
                isSaving={loading}
                onCancel={onToggle}
                onEdit={() => setEditing(false)}
              />
            </Form>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(BrandModal);
