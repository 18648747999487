import React, { memo, useCallback, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { FaPen } from 'react-icons/fa';

import { MdLocalOffer } from 'react-icons/md';

import { Button, Spinner, Table } from 'reactstrap';

import DeleteButton from '../DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

interface ProductsTableProps {
  products: any;
  loading?: any;
  onOpen?: Function;
  doUpdate?: Function;
}

function ProductsTable({ products, loading = false, onOpen, doUpdate }: ProductsTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/products/${id}`;

        const res = await api.delete(url);

        const data = res?.data;

        if (data.result) {
          notification.$s(util.t('PRODUCT_DELETED'));

          doUpdate();
        }
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      products?.data?.map((product) => (
        <tr key={product.inventory_id}>
          <td>
            <img
              className="img-fluid"
              width="30"
              src={product.thumbnailTenant ? product.thumbnailTenant : product.thumbnail}
              alt={product.name}
            />
          </td>
          <td width="20%">{product.name}</td>
          <td>{product.bar_code}</td>
          <td>{product.author_name}</td>
          <td>{product.brand_name}</td>
          <td width="10%">R$ {util.format(product.price)}</td>
          <td width="10%">
            {product.product_price > 0 ? 'R$ ' + util.format(product.product_price) : '-'}
          </td>
          <td>{product.stock}</td>
          <td>{product.company_name}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              <Link
                className={`${product.public ? 'disabled-link' : ''}`}
                to={`/products/edit/${product.inventory_id}`}>
                <Button
                  disabled={!!product.public}
                  className="mr-1"
                  onClick={() => onOpen(product.inventory_id)}
                  size="sm"
                  outline
                  color="info">
                  <FaPen />
                </Button>
              </Link>
              <Button
                size="sm"
                className="mr-1"
                outline
                color="info"
                onClick={() => onOpen(product.inventory_id)}>
                <MdLocalOffer />
              </Button>
              <DeleteButton
                onDelete={() => doDelete(product.inventory_id)}
                loading={deleting.indexOf(product.inventory_id) > -1}
                disabled={!!product.public}
                size="sm"
              />
            </div>
          </td>
        </tr>
      )),
    [products, deleting, onOpen, doDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={10}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (products?.data?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={10}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('IMAGE')}</th>
            <th>{util.t('TITLE')}</th>
            <th>{util.t('ISBN')}</th>
            <th>{util.t('AUTHOR')}</th>
            <th>{util.t('BRAND')}</th>
            <th>{util.t('ORIGINAL_VALUE')}</th>
            <th>{util.t('EDITED_VALUE')}</th>
            <th>{util.t('QUANTITY_STOCK')}</th>
            <th>{util.t('DISTRIBUTION_CENTER')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(ProductsTable);
