import React, { memo } from 'react';

import { OrderStatus } from '~/assets/constants';
import util from '~/assets/util';

const defaultStatus = Object.entries(OrderStatus);

interface StatusSelectProps {
  status?: any;
  required?: any;
  [key: string]: any;
}

function StatusSelect({ status = null, ...props }: StatusSelectProps) {
  return (
    <>
      <select className="form-control" {...props}>
        <option value="">{util.t(props.required ? 'SELECT' : 'ALL')}</option>
        {(status || defaultStatus).map((status) => {
          const id = status[1];
          const label = status[0];

          return (
            <option key={id} value={id}>
              {util.t(label)}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default memo(StatusSelect);
