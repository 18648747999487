import React, { memo, useState, useEffect } from 'react';

import { Button, InputGroup, InputGroupAddon, Table } from 'reactstrap';

import { FaCheck } from 'react-icons/fa';

import AttributeRow from '../AttributeRow';
import AttributeInput from '../AttributeInput';
import SearchAttributeInput from '../SearchAttributeInput';

import notification from '~/services/notification';

import util from '~/assets/util';

import './index.scss';

interface AttributesTableProps {
  onChange: Function;
  attributes: any;
  [key: string]: any;
}

function AttributesTable({ onChange, ...props }: AttributesTableProps) {
  const NEW_ATTRIBUTE_DEFAULT_STATE: any = {
    value: '',
    name: '',
    type: 'text',
    tenant_id: true,
    attribute_id: 0,
  };

  const [attributes, setAttributes] = useState(props.attributes || []);
  const [newAttribute, setNewAttribute] = useState(NEW_ATTRIBUTE_DEFAULT_STATE);

  const handleChangeValue = (ev, index) => {
    const aux = [...attributes];

    aux[index] = {
      ...aux[index],
      value: ev.target.value || ev.target.value,
    };

    setAttributes(aux);
  };

  const removeAttribute = (index) => {
    const aux = [...attributes];

    aux.splice(index, 1);

    setAttributes(aux);
  };

  const createProductAttribute = () => {
    const attributeId = newAttribute.attribute_id;

    const exists = attributes.find(
      (el) => el.attribute_id == attributeId || el.name == newAttribute.name,
    );

    if (exists) {
      return notification.$e(util.t('ATTRIBUTE_ALREADY_EXISTS'));
    }

    attributes.push(newAttribute);

    setAttributes(attributes);

    setNewAttribute(NEW_ATTRIBUTE_DEFAULT_STATE);
  };

  const handleChangeNewAttribute = (option) => {
    const tenantId = option?.__isNew__ || option?.tenant_id;

    const attributeId = option?.__isNew__ ? option.name : option?.attribute_id;

    setNewAttribute({
      ...newAttribute,
      name: option?.name || option?.label,
      attribute_id: attributeId,
      tenant_id: tenantId,
      type: option?.type,
    });
  };

  const handleCreateAttribute = (attribute) => {
    setNewAttribute({
      name: attribute.name,
      attribute_id: attribute.attribute_id,
      tenant_id: attribute.tenant_id,
      type: attribute.type,
    });
  };

  useEffect(() => {
    onChange(attributes);
  }, [attributes]);

  return (
    <Table bordered {...props}>
      <thead>
        <tr>
          <th>{util.t('ATTRIBUTE')}</th>
          <th>{util.t('VALUE')}</th>
        </tr>
      </thead>
      <tbody>
        {attributes.map((attribute, index) => (
          <AttributeRow
            key={attribute.attribute_id}
            onRemove={() => removeAttribute(index)}
            attribute={attribute}
            onChange={(ev) => handleChangeValue(ev, index)}
          />
        ))}
        <tr>
          <td>
            <SearchAttributeInput
              value={newAttribute}
              onChange={handleChangeNewAttribute}
              onCreate={handleCreateAttribute}
            />
          </td>
          <td>
            <InputGroup>
              <AttributeInput
                attribute={newAttribute}
                onChange={(ev) =>
                  setNewAttribute({ ...newAttribute, value: ev.floatValue || ev.target.value })
                }
              />

              <InputGroupAddon addonType="append">
                <Button color="success" type="button" onClick={createProductAttribute}>
                  <FaCheck />
                </Button>
              </InputGroupAddon>
            </InputGroup>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

export default memo(AttributesTable);
