import React from 'react';

import { NavLink } from 'react-router-dom';

import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';

import {
  FaBox,
  FaCog,
  FaTags,
  FaStore,
  FaImages,
  FaListAlt,
  FaInfoCircle,
  FaUserFriends,
  FaRegWindowRestore,
  FaThLarge,
  FaWindowMaximize,
  FaCode,
  FaGlobe,
} from 'react-icons/fa';

import { BsFileRichtext } from 'react-icons/bs';

import { AiFillApi } from 'react-icons/ai';

import util from '~/assets/util';

import './index.scss';

interface SidebarProps {
  collapsed: any;
  setCollapsed: Function;
}

function Sidebar({ collapsed, setCollapsed }: SidebarProps) {
  const isActive = (route) => {
    const pathname = window.location.pathname;
    const pathSplit = pathname.split('/');

    if (pathname == route) {
      return true;
    }

    if (pathSplit[1] == route.replace('/', '')) {
      return true;
    }
  };

  return (
    <div className="navbar-container">
      <ProSidebar
        width={240}
        breakPoint="md"
        toggled={!collapsed}
        collapsed={collapsed}
        onToggle={() => {
          setCollapsed(!collapsed);
        }}>
        <Menu iconShape="square">
          <li className="menu-title">{util.t('MENU')}</li>
          <MenuItem
            active={isActive('/') || isActive('/orders') || isActive('/home')}
            icon={<FaListAlt size={18} />}>
            <NavLink to="/">{util.t('ORDERS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/users')} icon={<FaUserFriends size={18} />}>
            <NavLink to="/users">{util.t('USERS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/products')} icon={<FaBox size={18} />}>
            <NavLink to="/products">{util.t('PRODUCTS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/categories')} icon={<FaThLarge size={18} />}>
            <NavLink to="/categories">{util.t('CATEGORIES')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/banners')} icon={<FaImages size={18} />}>
            <NavLink to="/banners">{util.t('BANNERS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/catalogues')} icon={<FaWindowMaximize size={18} />}>
            <NavLink to="/catalogues">{util.t('CATALOGUES')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/sections')} icon={<FaRegWindowRestore size={18} />}>
            <NavLink to="/sections">{util.t('IMAGE_SECTION')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/policies')} icon={<BsFileRichtext size={18} />}>
            <NavLink to="/policies">{util.t('POLICIES')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/general')} icon={<FaInfoCircle size={18} />}>
            <NavLink to="/general">{util.t('GENERAL_INFORMATION')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/settings')} icon={<FaCog size={18} />}>
            <NavLink to="/settings">{util.t('SETTINGS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/hosts')} icon={<FaGlobe size={18} />}>
            <NavLink to="/hosts">{util.t('DOMAINS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/coupons')} icon={<FaTags size={18} />}>
            <NavLink to="/coupons">{util.t('COUPONS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/stores')} icon={<FaStore size={18} />}>
            <NavLink to="/stores">{util.t('DISTRIBUTION_CENTERS')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/integration')} icon={<AiFillApi size={18} />}>
            <NavLink to="/integration">{util.t('API_INTEGRATION')}</NavLink>
          </MenuItem>
          <MenuItem active={isActive('/style-editor')} icon={<FaCode size={18} />}>
            <NavLink to="/style-editor">{util.t('STYLE_EDITOR')}</NavLink>
          </MenuItem>
        </Menu>
      </ProSidebar>
    </div>
  );
}

export default Sidebar;
