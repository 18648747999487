import React, { memo } from 'react';

import { format, parseISO } from 'date-fns';

import { Table } from 'reactstrap';

import Status from './Status';

import orderService from '~/services/order';

import util from '~/assets/util';

interface OrderDataProps {
  onChange: Function;
  storeOrder: any;
}

function OrderData({ onChange, storeOrder }: OrderDataProps) {
  const id = storeOrder.store_order_id;

  return (
    <Table bordered>
      <tbody>
        <tr>
          <th>{util.t('ORDER_NUMBER')} </th>
          <td>{id}</td>
        </tr>
        <tr>
          <th>{util.t('MAIN_ORDER_NUMBER')} </th>
          <td>{storeOrder.order_id}</td>
        </tr>
        <tr>
          <th>{util.t('DATE')} </th>
          <td>{format(parseISO(storeOrder.created_at), 'dd/MM/yyyy HH:mm')}</td>
        </tr>
        <tr>
          <th>{util.t('STATUS')} </th>
          <td className="d-flex align-items-center justify-content-between flex-wrap">
            <Status
              onSave={(value) => {
                onChange(value);
              }}
              storeOrder={storeOrder}
            />
          </td>
        </tr>
        {storeOrder.order.payment_confirmed_at && (
          <tr>
            <th>{util.t('PAYMENT_CONFIRMED_AT')} </th>
            <td>{format(parseISO(storeOrder.order.payment_confirmed_at), 'dd/MM/yyyy HH:mm')}</td>
          </tr>
        )}
        <tr>
          <th>{util.t('PAYMENT_METHOD')} </th>
          <td>{orderService.getPaymentMethod(storeOrder.order.payment_method)}</td>
        </tr>
        <tr>
          <th>{util.t('SHIPPING_METHOD')} </th>
          <td>{storeOrder.shipping_method.name}</td>
        </tr>
        <tr>
          <th>{util.t('VALUE')} </th>
          <td>R$ {util.format(storeOrder.gross_amount)}</td>
        </tr>
        {storeOrder.order.affiliate_id && (
          <>
            <tr>
              <th>{util.t('SALE_BY_AFFILIATE')}</th>
              <td>{storeOrder?.order?.affiliate.name}</td>
            </tr>
            <tr>
              <th>{util.t('AFFILIATED_COMMISSION')}</th>
              <td>
                R${' '}
                {util.format(
                  (storeOrder?.subtotal * storeOrder?.order?.affiliate_commission_percentage) / 100,
                )}
              </td>
            </tr>
          </>
        )}
        {storeOrder.invoice_number && (
          <>
            <tr>
              <th>{util.t('INVOICE_ACCESS_KEY')} </th>
              <td>{storeOrder.invoice_number}</td>
            </tr>
            <tr>
              <th>{util.t('INVOICE_NUMBER')} </th>
              <td>{storeOrder.tax_invoice}</td>
            </tr>
          </>
        )}
      </tbody>
    </Table>
  );
}

export default memo(OrderData);
