import React, { memo, useEffect, useState } from 'react';
import { CSVReader } from 'react-papaparse';

import { FormGroup, Row, Col, Label, Spinner } from 'reactstrap';

import FormButtons from '~/components/FormButtons';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface UploadProps {
  onClose: Function;
  onSave: Function;
}

function Upload({ onClose, onSave }: UploadProps) {
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [franchising, setFranchising] = useState(null);

  const [usersData, setUsersData]: any = useState({
    franchising_id: '',
    users: '',
  });

  const handleChange = (ev) => {
    const name = ev.target.name;

    setUsersData({ ...usersData, [name]: ev.target.value });
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = {
        ...usersData,
      };

      const url = `admin/franchising/customers`;

      const res = await api.post(url, form);

      const data = res?.data;

      if (data.users) {
        onSave();
        onClose();

        notification.$s(util.t('SUCCESSFULLY_IMPORTED_CPF'));
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const cancelEdit = () => {
    return onClose();
  };

  useEffect(() => {
    async function loadFranchising() {
      try {
        const url = `admin/franchising/list`;

        const res = await api.get(url);

        const data = res?.data;

        if (data.franchisees) {
          setFranchising(data.franchisees);
        }

        setLoading(false);
      } catch (e) {
        notification.$e(e);

        console.error(e);
      } finally {
        setSaving(false);
        setLoading(false);
      }
    }

    loadFranchising();
  }, []);

  const handleChangeFile = (data) => {
    const users = [];
    data?.map((user) => user?.data[0] !== '' && users.push({ cpf: user?.data[0] }));

    setUsersData({ ...usersData, users: users });
  };

  const handleOnError = (err, file, inputElem, reason) => {
    console.log(err);
  };

  const handleOnRemoveFile = (data) => {
    setUsersData({ ...usersData, users: null });
  };

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-2">
        <>
          <fieldset>
            <Row className="mb-2" form>
              <p>
                Importe uma planilha de CPF&apos;s e vincule essa planilha a uma franquia, para
                quando o usuário criar uma conta ou realizar uma compra este CPF será vinculado a
                franquia.
              </p>
            </Row>
            <Row form>
              <Col md="12" lg="12">
                <FormGroup>
                  <CSVReader
                    accept=".csv"
                    onDrop={handleChangeFile}
                    onError={handleOnError}
                    noDrag
                    addRemoveButton
                    onRemoveFile={handleOnRemoveFile}>
                    <span>Clique para enviar um arquivo CSV</span>
                  </CSVReader>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md="12" lg="12">
                <FormGroup>
                  <Label htmlFor="name">{util.t('FRANCHISING')}</Label>
                  {loading ? (
                    <Spinner color="primary" className="d-block m-auto" />
                  ) : (
                    <select
                      className="form-control"
                      name="franchising_id"
                      onChange={handleChange}
                      required>
                      <option value="">Selecione uma franquia</option>
                      {franchising?.map((fr, index) => (
                        <option key={index} value={fr.tenant_id}>
                          {fr.name}
                        </option>
                      ))}
                    </select>
                  )}
                </FormGroup>
              </Col>
            </Row>
          </fieldset>
          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <FormButtons
                  isEditing={false}
                  isSaving={saving}
                  onCancel={cancelEdit}
                  onEdit={false}
                />
              </footer>
            </Col>
          </Row>
        </>
      </form>
    </>
  );
}

export default memo(Upload);
