import React, { memo, useCallback, useMemo, useState } from 'react';

import { Alert, Button, Spinner, Table } from 'reactstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { FaSearchPlus, FaPencilAlt, FaInfo } from 'react-icons/fa';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

interface CataloguesTableProps {
  setCatalogues: any;
  catalogues: any;
  loading?: any;
  setLoading?: Function;
  doUpdate?: Function;
  onOpen?: Function;
}

function CataloguesTable({
  setCatalogues,
  catalogues,
  loading = false,
  setLoading,
  doUpdate,
  onOpen,
}: CataloguesTableProps) {
  const [enableDragging, setEnableDragging] = useState(false);
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/catalogues/delete/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const savePositions = async () => {
    if (loading) return false;

    setLoading(true);

    try {
      const url = 'admin/catalogues/positions/update';

      const form = catalogues.map((catalogue, index) => ({
        catalogue_id: catalogue.catalogue_id,
        position: index + 1,
      }));

      await api.post(url, { form });

      doUpdate();

      notification.$s(util.t('UPDATED_SUCCESSFULLY'));

      setEnableDragging(false);
    } catch (e) {
      console.error(e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const endIndex = result.destination.index;
    const startIndex = result.source.index;

    const auxCatalogues = [...catalogues];

    const [removed] = auxCatalogues.splice(startIndex, 1);

    auxCatalogues.splice(endIndex, 0, removed);

    setCatalogues(auxCatalogues);
  };

  const doCancelDragging = () => {
    setEnableDragging(false);
  };

  const list = useMemo(
    () =>
      catalogues?.map((catalogue, index) => (
        <Draggable
          isDragDisabled={!enableDragging}
          key={catalogue.catalogue_id}
          draggableId={`${catalogue.catalogue_id}`}
          index={index}>
          {(provided) => (
            <tr
              className="w-100"
              key={catalogue.catalogue_id}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              {...provided.dragHandleProps}>
              {!enableDragging && <td>{catalogue.position}</td>}
              <td className={enableDragging ? 'w-10' : ''}>{catalogue.name}</td>
              {!enableDragging && <td>{catalogue.subtitle}</td>}
              {!enableDragging && <td>{util.t(catalogue.active ? 'ACTIVE' : 'INACTIVE')}</td>}
              {!enableDragging && (
                <td>
                  <div className="d-flex align-center justify-content-end">
                    <Button
                      className="mr-1"
                      onClick={() => onOpen(catalogue.catalogue_id)}
                      size="sm"
                      outline
                      color="info">
                      <FaSearchPlus />
                    </Button>

                    <DeleteButton
                      onDelete={() => doDelete(catalogue.catalogue_id)}
                      loading={deleting.indexOf(catalogue.catalogue_id) > -1}
                      size="sm"
                    />
                  </div>
                </td>
              )}
            </tr>
          )}
        </Draggable>
      )),
    [catalogues, deleting, enableDragging, doDelete, onOpen],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (catalogues?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      {enableDragging && (
        <Alert color="info">
          <FaInfo />
          {util.t('CATALOGUE_POSITIONS_TIP')}
        </Alert>
      )}

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="catalogues-table" isDropDisabled={!enableDragging}>
          {(provided) => (
            <Table
              className="table-fixed"
              hover
              responsive
              innerRef={provided.innerRef}
              {...provided.droppableProps}>
              <thead className="thead-light">
                <tr>
                  {!enableDragging && <th>{util.t('POSITION')}</th>}
                  <th className={enableDragging ? 'w-100' : ''}>{util.t('TITLE')}</th>
                  {!enableDragging && <th>{util.t('SUBTITLE')}</th>}
                  {!enableDragging && <th>{util.t('STATUS')}</th>}
                  {!enableDragging && <th className="text-right">{util.t('ACTION')}</th>}
                </tr>
              </thead>

              <tbody>
                {renderBody()}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      <footer className="d-flex justify-content-end mt-4">
        {enableDragging ? (
          <>
            <Button onClick={doCancelDragging} className="my-3 mr-1" color="danger">
              {util.t('CANCEL')}
            </Button>

            <Button onClick={savePositions} className="my-3" color="primary">
              {util.t('SAVE')}
            </Button>
          </>
        ) : (
          <Button
            onClick={() => setEnableDragging(true)}
            color="primary"
            disabled={catalogues.length < 2}>
            <FaPencilAlt className="mr-2" />
            {util.t('EDIT_POSITIONS')}
          </Button>
        )}
      </footer>
    </>
  );
}

export default memo(CataloguesTable);
