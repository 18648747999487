import React, { memo, useRef, useState } from 'react';

import { Button, InputGroup, InputGroupAddon, UncontrolledTooltip } from 'reactstrap';

import { FaPlus } from 'react-icons/fa';

import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import debounce from 'lodash.debounce';

import CategoryModal from '../CategoryModal';

import api from '~/services/api';

import util from '~/assets/util';

interface SearchCategoryInputProps {
  onChange: Function;
  setRef?: any;
  isMulti?: any;
  value?: any;
  [key: string]: any;
}

function SearchCategoryInput({
  onChange,
  setRef = null,
  isMulti,
  value,
  ...props
}: SearchCategoryInputProps) {
  const [showModal, setShowModal] = useState(false);
  const [selectedType, setSelectedType] = useState('all');
  const newCategoryButtonRef = useRef(null);

  const searchCategories = async (inputValue) => {
    try {
      const res = await api.get(
        `admin/menu-categories/search?type=${selectedType}&q=${inputValue}`,
      );

      const { categories } = res?.data;

      if (!categories) {
        return [];
      }

      return categories;
    } catch (e) {
      console.error(e);
    }
  };

  const getOptionLabel = (category) => {
    let label = category.name;

    if (category.parent) {
      label += ` (${category.parent.name})`;
    }

    return label;
  };

  const loadCategories = (inputValue) =>
    new Promise((resolve) => {
      resolve(searchCategories(inputValue));
    });

  const debouncedLoadCategories = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadCategories(inputValue).then((options) => callback(options));
  }, 1000);

  const handleNewCategory = (category) => {
    onChange(isMulti ? value.concat(category) : category);

    setShowModal(false);
  };

  const types = [
    { value: 'all', label: 'All' },
    { value: 'global', label: 'Global' },
    { value: 'tenant', label: 'Tenant' },
  ];

  return (
    <>
      <InputGroup>
        <Select
          name="type"
          placeholder={util.t('SELECT')}
          value={types.find((option) => option.value === selectedType)}
          onChange={(selectedOption) => setSelectedType(selectedOption.value)}
          isSearchable={false}
          options={types}
          className="react-select-full-width mr-1"
        />

        <AsyncSelect
          ref={setRef}
          className="react-select-full-width"
          placeholder={util.t('SEARCH_THREE_DOTS')}
          getOptionValue={(option) => option.category_id}
          getOptionLabel={getOptionLabel}
          loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
          loadOptions={debouncedLoadCategories}
          {...props}
          isClearable
          noOptionsMessage={() => util.t('NO_OPTION')}
          onChange={onChange}
          required
        />

        <InputGroupAddon addonType="append">
          <Button
            className="new-category-button"
            alt={util.t('NEW_CATEGORY')}
            type="button"
            onClick={() => setShowModal(true)}
            size="sm"
            color="primary"
            innerRef={(ref) => {
              newCategoryButtonRef.current = ref;
            }}>
            <FaPlus />
          </Button>

          <UncontrolledTooltip target={newCategoryButtonRef}>
            {util.t('NEW_CATEGORY')}
          </UncontrolledTooltip>
        </InputGroupAddon>
      </InputGroup>

      <CategoryModal
        id={0}
        isOpen={showModal}
        onSave={handleNewCategory}
        onToggle={() => setShowModal(false)}
      />
    </>
  );
}

export default memo(SearchCategoryInput);
