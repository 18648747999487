import React, { useEffect } from 'react';

import { Spinner } from 'reactstrap';

import { useNavigate } from 'react-router-dom';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

function PagSeguroConnect() {
  const navigate = useNavigate();

  const authorize = async () => {
    const query = new URLSearchParams(window.location.search);

    const code = query.get('code');

    if (code == null) {
      navigate('/settings');

      return notification.$e(util.t('PASSEGURO_AUTH_CODE_IS_REQUIRED'));
    }

    try {
      await api.post('admin/pagseguro-connect', {
        code,
      });

      notification.$s(null, util.t('PAGSEGURO_CONNECTED_SUCCESSFULLY'), true, false);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      navigate('/settings');
    }
  };

  useEffect(() => {
    authorize();
  }, []);

  return (
    <main className="card my-3">
      <div className="card-body">
        <header>
          <div className="container-fluid p-3">
            <Spinner />
          </div>
        </header>
      </div>
    </main>
  );
}

export default PagSeguroConnect;
