import React, { memo, useRef, useState } from 'react';

import { FaSearch } from 'react-icons/fa';

import SearchAuthorInput from '../Authors/SearchAuthorInput';
import Button from '../Button';

import Modal from './Modal';

import api from '~/services/api';

import util from '~/assets/util';

import styles from './index.module.scss';

interface SelectAuthorProductsProps {
  disabled?: any;
  onConfirm: Function;
  maxProducts: any;
  disabledBarcodes?: any;
}

function SelectAuthorProducts({
  disabled,
  onConfirm,
  maxProducts,
  disabledBarcodes,
}: SelectAuthorProductsProps) {
  const [authorId, setAuthorId] = useState(null);
  const [books, setBooks] = useState([]);
  const [nextCursor, setNextCursor] = useState(null);
  const [page, setPage] = useState(1);
  const [searching, setSearching] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const selectRef = useRef(null);

  async function loadBooks(page = 1) {
    setSearching(true);
    setPage(page);

    const aux = page === 1 ? [] : [...books];

    const params = { cursor: nextCursor };

    try {
      const res = await api.get(`admin/products/author/${authorId}`, { params });

      const { data, next_cursor } = res?.data;

      setBooks(aux.concat(data));
      setNextCursor(next_cursor);
    } catch (e) {
      console.error(e);
    } finally {
      setSearching(false);
    }
  }

  const submitSearch = () => {
    setNextCursor(null);
    loadBooks();
    setShowModal(true);
  };

  const onSelectAuthor = async (option) => {
    if (!option) {
      setAuthorId(null);
      return;
    }

    setAuthorId(option.value);
  };

  return (
    <div className={styles.container}>
      <SearchAuthorInput
        canCreate={false}
        innerRef={(ref) => (selectRef.current = ref)}
        placeholder="TYPE_THE_AUTHOR_NAME"
        onChange={onSelectAuthor}
      />

      <Button
        color="primary"
        className={styles.searchButton}
        type="button"
        disabled={authorId == null}
        onClick={submitSearch}>
        <FaSearch className="mr-1" />

        <span>{util.t('SEARCH_PRODUCTS')}</span>
      </Button>

      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        loadMore={() => loadBooks(page + 1)}
        page={page}
        searching={searching}
        searchResults={books}
        onConfirmItems={onConfirm}
        maxProducts={maxProducts}
        canLoadMore={nextCursor != null}
        disabledBarcodes={disabledBarcodes}
      />
    </div>
  );
}

export default memo(SelectAuthorProducts);
