import React, { memo } from 'react';

import { Table } from 'reactstrap';

import util from '~/assets/util';

function OrderItems({ items }: any) {
  return (
    <Table responsive bordered>
      <thead>
        <tr>
          <th>{util.t('ISBN')}</th>
          <th>{util.t('PRODUCT')}</th>
          <th className="text-center">{util.t('UNIT_PRICE')}</th>
          <th className="text-center">{util.t('QUANTITY')}</th>
          <th>{util.t('TOTAL')}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr key={item.store_order_item_id}>
            <td>{item.bar_code}</td>
            <td>{item.name}</td>
            <td className="text-center">R$ {util.format(item.price)}</td>
            <td className="quantity text-center">{item.quantity}</td>
            <td>R$ {util.format(item.total)}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

export default memo(OrderItems);
