import React, { memo } from 'react';

import MaskedInput from 'react-text-mask';

const InputZipCode = (props) => {
  return (
    <MaskedInput
      {...props}
      type="text"
      mask={[/[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/, /[0-9]/]}
    />
  );
};

export default memo(InputZipCode);
