import React, { memo, useCallback, useMemo, useState } from 'react';

import { Link } from 'react-router-dom';

import { Button, Spinner, Table } from 'reactstrap';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import { FaSearchPlus } from 'react-icons/fa';

import DeleteButton from '../DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface StoresTableProps {
  stores: any[];
  loading?: any;
  setStores?: any;
  doUpdate?: any;
  editPriority?: any;
}

function StoresTable({
  setStores,
  stores,
  loading = false,
  doUpdate,
  editPriority = true,
}: StoresTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/tenants/stores/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [doUpdate, deleting],
  );

  const list = useMemo(
    () =>
      stores.map((store, index) => (
        <Draggable
          isDragDisabled={!editPriority}
          key={store.store_id}
          draggableId={`${store.store_id}`}
          index={index}>
          {(provided) => (
            <tr
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              {...provided.dragHandleProps}>
              {!editPriority && <td>{store.store_id}</td>}
              <td className={editPriority ? 'w-10' : ''}>{store.company_name}</td>
              {!editPriority && <td>{store.cnpj}</td>}
              {!editPriority && <td>{store.priority}</td>}
              {!editPriority && (
                <td>
                  <div className="d-flex align-store justify-content-end">
                    <Link to={`/stores/edit/${store.store_id}`}>
                      <Button className="mr-1" size="sm" outline color="info">
                        <FaSearchPlus />
                      </Button>
                    </Link>
                    <DeleteButton
                      onDelete={() => doDelete(store.store_id)}
                      loading={deleting.indexOf(store.store_id) > -1}
                      disabled={store.store_id == 1 ? true : false}
                      size="sm"
                    />
                  </div>
                </td>
              )}
            </tr>
          )}
        </Draggable>
      )),
    [stores, deleting, editPriority, doDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-store w-100" colSpan={editPriority ? 1 : 5}>
            <Spinner size="sm" color="primary" className="d-block m-auto" />
          </th>
        </tr>
      );
    }

    if (stores.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination || result.destination.index === result.source.index) {
      return;
    }

    const endIndex = result.destination.index;
    const startIndex = result.source.index;

    const auxStores = [...stores];

    const [removed] = auxStores.splice(startIndex, 1);

    auxStores.splice(endIndex, 0, removed);

    setStores(auxStores);
  };

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="stores-table" isDropDisabled={!editPriority}>
          {(provided) => (
            <Table
              className="table-fixed"
              innerRef={provided.innerRef}
              {...provided.droppableProps}
              hover
              responsive>
              <thead className="thead-light">
                <tr>
                  {!editPriority && <th>{util.t('CODE')}</th>}
                  <th>{util.t('COMPANY_NAME')}</th>
                  {!editPriority && <th>{util.t('LABEL_CNPJ')}</th>}
                  {!editPriority && <th>{util.t('PRIORITY_FACTOR')}</th>}
                  {!editPriority && <th className="text-right">{util.t('ACTION')}</th>}
                </tr>
              </thead>

              <tbody>
                {renderBody()}
                {provided.placeholder}
              </tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default memo(StoresTable);
