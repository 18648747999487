import React, { memo, useState, useEffect } from 'react';

import { useRef } from 'react';
import { Editor } from '@tinymce/tinymce-react';

import {
  Card,
  CardBody,
  CardHeader,
  Modal,
  Form,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';

import notification from '~/services/notification';
import api from '~/services/api';

import util from '~/assets/util';

import styles from './index.module.scss';

interface PoliciesModalProps {
  onClose: any;
  isOpen?: any;
  load: any;
  police?: any;
}

function PoliciesModal({ onClose, isOpen = true, load, police }: PoliciesModalProps) {
  const editorRef = useRef(null);
  const [title, setTitle] = useState('');

  useEffect(() => {
    if (!police) {
      return setTitle('');
    }

    setTitle(police.title);
  }, [police]);

  const doSave = async (e) => {
    e.preventDefault();

    try {
      if (!editorRef?.current?.getContent().trim()) {
        notification.$e(util.t('REQUIRED_CONTENT_POLICIE'));
        return;
      }

      let url = '/admin/policies/register';
      if (police) {
        url = `/admin/policies/update/${police.police_id}`;
      }

      const form = {
        title: title,
        text: editorRef.current.getContent(),
      };

      await api.post(url, form);

      onClose();
      load();
      notification.$s(util.t('REGISTER_SUCCESSFULLY'));
    } catch (e) {
      notification.$e(e);
    }
  };

  return (
    <Modal
      className={styles.policieModal}
      autoFocus={false}
      isOpen={isOpen}
      size="xl"
      toggle={onClose}
      unmountOnClose>
      <Card>
        <CardHeader>{util.t('POLICIES')}</CardHeader>
        <CardBody>
          {isOpen && (
            <>
              <Form onSubmit={doSave}>
                <Row form>
                  <Col lg="6">
                    <FormGroup>
                      <Label htmlFor="title">{util.t('TITLE')}</Label>

                      <Input
                        id="title"
                        name="title"
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}
                        max="255"
                        required
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Editor
                  apiKey={process.env.REACT_APP_TINY_API_KEY}
                  onInit={(_evt, editor) => {
                    editorRef.current = editor;
                    if (police) {
                      setTimeout(() => {
                        editor.setContent(police.text);
                      }, 0);
                    }
                  }}
                  init={{
                    height: 500,
                    menubar: false,
                    advcode_inline: true,
                    plugins:
                      'searchreplace autolink directionality visualblocks visualchars image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap linkchecker emoticons autosave fullscreen code',
                    toolbar:
                      'undo redo | blocks | ' +
                      'bold italic forecolor | alignleft aligncenter ' +
                      'alignright alignjustify | bullist numlist outdent indent | ' +
                      'removeformat | code | help',
                    content_style:
                      'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                  }}
                />
                <Col className="mt-3">
                  <Row className="justify-content-end">
                    <Button color="danger" type="button" onClick={onClose}>
                      {util.t('CANCEL')}
                    </Button>
                    <Button className="ml-2" color="primary" type="submit">
                      {police ? util.t('UPDATE') : util.t('SAVE')}
                    </Button>
                  </Row>
                </Col>
              </Form>
            </>
          )}
        </CardBody>
      </Card>
    </Modal>
  );
}

export default memo(PoliciesModal);
