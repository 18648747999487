import React, { memo, useCallback, useMemo, useState } from 'react';

import { FaSearchPlus } from 'react-icons/fa';
import { Button, Spinner, Table } from 'reactstrap';

import DeleteButton from '../DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

import './index.scss';

interface BannersTableProps {
  banners: any;
  loading?: any;
  onOpen: Function;
  doUpdate: Function;
}

function BannersTable({ banners, loading = false, onOpen, doUpdate }: BannersTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/carousel/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      banners?.data?.map((banner) => (
        <tr key={banner.banner_id}>
          <td width="20%">
            <img className="img-fluid" src={banner.url} alt={banner.title} />
          </td>
          <td>{banner.title}</td>
          <td>{banner.position}</td>
          <td>{util.t(banner.active ? 'ACTIVE' : 'INACTIVE')}</td>
          <td>
            <div className="d-flex align-center justify-content-end">
              <Button
                className="mr-1"
                onClick={() => onOpen(banner.banner_id)}
                size="sm"
                outline
                color="info">
                <FaSearchPlus />
              </Button>

              <DeleteButton
                onDelete={() => doDelete(banner.banner_id)}
                loading={deleting.indexOf(banner.banner_id) > -1}
                size="sm"
              />
            </div>
          </td>
        </tr>
      )),
    [banners, deleting, doDelete, onOpen],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={6}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (banners?.data?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={5}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('IMAGE')}</th>
            <th>{util.t('TITLE')}</th>
            <th>{util.t('POSITION')}</th>
            <th>{util.t('STATUS')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(BannersTable);
