import React, { memo, useEffect, useRef, useState } from 'react';

import {
  FormGroup,
  Row,
  Col,
  Label,
  Input,
  Spinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';

import { useNavigate } from 'react-router-dom';
import CurrencyInput from 'react-currency-masked-input';

import ShippingMethods from './ShippingMethods';

import Button from '~/components/Button';
import InputCnpj from '~/components/InputCnpj';
import InputPhone from '~/components/InputPhone';
import StateSelect from '~/components/StateSelect';
import InputZipCode from '~/components/InputZipCode';
import StatusSwitch from '~/components/StatusSwitch';

import api from '~/services/api';
import viacep from '~/services/viacep';
import notification from '~/services/notification';

import util from '~/assets/util';

function Form({ id }: any) {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);

  const [correiosCredentials, setCorreiosCredentials] = useState({
    postcard: '',
    token: '',
    user: '',
  });

  const [store, setStore] = useState({
    cnpj: '',
    phone: '',
    company_name: '',
    priority: 1,
    service_additional_days: 0,
    order_additional_days: 0,
    free_shipping: false,
    free_shipping_min_value: 0,
    shipping_methods_ids: [],
    local_pickup: false,
    local_pickup_min_days: 0,
    show_quantity_stock: false,
  });

  const [storeTenant, setStoreTenant] = useState({
    priority: 1,
    store_id: 0,
    tenant_id: 0,
    free_shipping: false,
    free_shipping_min_value: 0,
    local_pickup_catavento: false,
    local_pickup_min_days: 0,
    show_quantity_stock: false,
  });

  const [address, setAddress]: any = useState({
    address_id: 0,
    street: '',
    neighborhood: '',
    complement: '',
    number: '',
    state: '',
    city: '',
    zipcode: '',
    phone: '',
  });

  const inputCompanyNameRef = useRef(null);

  const handleChangeStore = (ev) => {
    const name = ev.target.name;

    setStore({ ...store, [name]: ev.target.value });
  };

  const handleChangeStoreTenant = (ev) => {
    const name = ev.target.name;

    setStoreTenant({ ...storeTenant, [name]: ev.target.value });
  };

  const handleChangeAddress = (ev) => {
    const name = ev.target.name;

    setAddress({ ...address, [name]: ev.target.value });
  };

  const handleChangeZipCode = async (ev) => {
    const value = ev.target.value;

    setAddress({ ...address, zipcode: value });

    const numbers = value.replace(/\D/g, '');

    if (numbers.length == 8) {
      const data = await viacep.search(numbers);

      setAddress({ ...address, ...data, zipcode: value });
    }
  };

  const doSave = async (ev) => {
    ev.preventDefault();

    if (!store.shipping_methods_ids.length) {
      return notification.$e(util.t('SHIPPING_METHOD_IS_REQUIRED'));
    }

    if (saving) {
      return false;
    }

    setSaving(true);

    const url = 'admin/tenants/stores';
    const form = { store, storeTenant, address, correiosCredentials };

    try {
      const res = id ? await api.put(`${url}/${id}`, form) : await api.post(url, form);

      const data = res?.data;

      if (data.store) {
        notification.$s(util.t('STORE_SAVED'));

        navigate('/stores');
      }
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    async function loadStore() {
      setLoading(true);

      try {
        const res = await api.get(`admin/tenants/stores/${id}`);

        const data = res?.data;

        data.store && setStore({ ...store, ...data.store });
        data.store_tenant && setStoreTenant(data.store_tenant);
        data.address && setAddress(data.address);
        data.correios_credentials && setCorreiosCredentials(data.correios_credentials);
      } catch (e) {
        notification.$e(e);

        navigate('/stores');

        console.error(e);
      } finally {
        setLoading(false);
      }
    }

    if (id) {
      loadStore();
    }
  }, []);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        {id && loading ? (
          <div className="p-5">
            <Spinner size="lg" className="d-block m-auto" color="primary" />
          </div>
        ) : (
          <>
            <fieldset>
              {id != 1 ? (
                <>
                  <Row form>
                    <Col md="7" lg="5">
                      <FormGroup>
                        <Label htmlFor="company_name">{util.t('COMPANY_NAME')}</Label>
                        <Input
                          className="form-control"
                          value={store.company_name}
                          onChange={handleChangeStore}
                          type="text"
                          maxLength={50}
                          name="company_name"
                          id="company_name"
                          required
                          ref={inputCompanyNameRef}
                          autoFocus
                        />
                      </FormGroup>
                    </Col>

                    <Col md="6" lg="4">
                      <FormGroup>
                        <Label htmlFor="cnpj">{util.t('LABEL_CNPJ')}</Label>
                        <InputCnpj
                          className="form-control"
                          value={store.cnpj}
                          name="cnpj"
                          id="cnpj"
                          onChange={handleChangeStore}
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="5" lg="3">
                      <FormGroup>
                        <Label htmlFor="phone">{util.t('PHONE')}</Label>
                        <InputPhone
                          className="form-control"
                          value={address.phone}
                          name="phone"
                          id="phone"
                          onChange={handleChangeAddress}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col>
                      <legend className="mt-3 mb-0">{util.t('ADDRESS')}</legend>
                    </Col>
                  </Row>

                  <hr />

                  <Row form>
                    <Col sm="4" lg="3" xl="2">
                      <FormGroup>
                        <Label htmlFor="zipcode">{util.t('ADDRESS_ZIPCODE')}</Label>
                        <InputZipCode
                          className="form-control"
                          value={address.zipcode}
                          name="zipcode"
                          id="zipcode"
                          onChange={handleChangeZipCode}
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg="6">
                      <FormGroup>
                        <Label htmlFor="street">{util.t('ADDRESS')}</Label>
                        <Input
                          className="form-control"
                          value={address.street}
                          onChange={handleChangeAddress}
                          type="text"
                          maxLength={50}
                          name="street"
                          id="street"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="3" lg="2">
                      <FormGroup>
                        <Label htmlFor="number">{util.t('ADDRESS_NUMBER')}</Label>
                        <Input
                          className="form-control"
                          value={address.number}
                          onChange={handleChangeAddress}
                          type="text"
                          maxLength={50}
                          name="number"
                          id="number"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col md="9" lg="4">
                      <FormGroup>
                        <Label htmlFor="complement">{util.t('ADDRESS_COMPLEMENT')}</Label>
                        <Input
                          className="form-control"
                          value={address.complement || ''}
                          onChange={handleChangeAddress}
                          type="text"
                          maxLength={50}
                          name="complement"
                          id="complement"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row form>
                    <Col lg="4">
                      <FormGroup>
                        <Label htmlFor="neighborhood">{util.t('ADDRESS_NEIGHBORHOOD')}</Label>
                        <Input
                          className="form-control"
                          value={address.neighborhood}
                          onChange={handleChangeAddress}
                          type="text"
                          name="neighborhood"
                          id="neighborhood"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <Label htmlFor="city">{util.t('ADDRESS_CITY')}</Label>
                        <Input
                          className="form-control"
                          value={address.city}
                          onChange={handleChangeAddress}
                          type="text"
                          name="city"
                          id="city"
                          required
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <Label htmlFor="state">{util.t('ADDRESS_STATE')}</Label>
                        <StateSelect
                          value={address.state}
                          onChange={handleChangeAddress}
                          emptyLabel={'SELECT'}
                          type="text"
                          name="state"
                          id="state"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Row form>
                <Col>
                  <legend className="mt-3 mb-0">{util.t('SHIPPING')}</legend>
                </Col>
              </Row>

              <hr />

              {id != 1 ? (
                <>
                  <Row className="mb-3" form>
                    <Col md="5">
                      <FormGroup>
                        <Label htmlFor="shipping_method_id">{util.t('SHIPPING_METHODS')}</Label>
                        <ShippingMethods
                          defaultSelected={store.shipping_methods_ids}
                          onChange={(ids) => {
                            setStore({ ...store, shipping_methods_ids: ids });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
              ) : null}

              <Row form>
                {id != 1 ? (
                  <>
                    <Col xs="12" sm="6">
                      <Row form>
                        <Col>
                          <Label className="form-legend">
                            <strong>{util.t('CORREIOS_INTEGRATION')}</strong>
                          </Label>
                        </Col>
                      </Row>

                      <Row form>
                        <Col lg="4">
                          <FormGroup>
                            <Label htmlFor="correios_user">{util.t('USER')}</Label>
                            <input
                              className="form-control"
                              type="text"
                              value={correiosCredentials.user}
                              id="correios_user"
                              name="correios_user"
                              onChange={(ev) =>
                                setCorreiosCredentials({
                                  ...correiosCredentials,
                                  user: ev.target.value,
                                })
                              }
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <Label htmlFor="correios_postcard">{util.t('POSTCARD')}</Label>
                            <input
                              className="form-control"
                              type="text"
                              value={correiosCredentials.postcard}
                              id="correios_postcard"
                              name="correios_postcard"
                              onChange={(ev) =>
                                setCorreiosCredentials({
                                  ...correiosCredentials,
                                  postcard: ev.target.value,
                                })
                              }
                              required={correiosCredentials.user?.length != 0}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="8">
                          <FormGroup>
                            <Label htmlFor="correios_token">{util.t('TOKEN')}</Label>
                            <input
                              className="form-control"
                              type="text"
                              value={correiosCredentials.token}
                              id="correios_token"
                              name="correios_token"
                              onChange={(ev) =>
                                setCorreiosCredentials({
                                  ...correiosCredentials,
                                  token: ev.target.value,
                                })
                              }
                              required={correiosCredentials.user?.length != 0}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs="12" sm="6">
                      <Row form>
                        <Col>
                          <Label className="form-legend">
                            <strong>{util.t('HANDLING_ADDITIONAL_DAYS')}</strong>
                          </Label>
                        </Col>
                      </Row>

                      <Row form>
                        <Col lg="4">
                          <FormGroup>
                            <Label htmlFor="service_additional_days">
                              {util.t('IN_STOCK_ITEMS')}
                            </Label>
                            <input
                              placeholder="0"
                              className="form-control"
                              type="number"
                              max="127"
                              step="1"
                              min="0"
                              value={store.service_additional_days}
                              id="service_additional_days"
                              name="service_additional_days"
                              onChange={handleChangeStore}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="4">
                          <FormGroup>
                            <Label htmlFor="order_additional_days">
                              {util.t('TO_ORDER_ITEMS')}
                            </Label>
                            <input
                              placeholder="0"
                              className="form-control"
                              type="number"
                              max="127"
                              step="1"
                              min="0"
                              value={store.order_additional_days}
                              id="order_additional_days"
                              name="order_additional_days"
                              onChange={handleChangeStore}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : null}

                <Col xs="12" sm="6">
                  <Row form>
                    <Col>
                      <Label className="form-legend" htmlFor="free_shipping">
                        <strong>{util.t('FREE_SHIPPING')}</strong>
                      </Label>
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg="4">
                      <FormGroup>
                        <Label>Status</Label>
                        <StatusSwitch
                          onChange={(status) =>
                            setStoreTenant({ ...storeTenant, free_shipping: status })
                          }
                          checked={!!storeTenant.free_shipping}
                          name="active"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <Label htmlFor="free_shipping_min_value">
                          {util.t('FREE_SHIPPING_MIN_VALUE')}
                        </Label>

                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>R$</InputGroupText>
                          </InputGroupAddon>

                          <CurrencyInput
                            disabled={!storeTenant.free_shipping}
                            placeholder="0"
                            className="form-control"
                            value={storeTenant.free_shipping_min_value + ''}
                            id="free_shipping_min_value"
                            name="free_shipping_min_value"
                            onChange={(ev, maskedValue) => {
                              setStoreTenant({
                                ...storeTenant,
                                free_shipping_min_value: maskedValue,
                              });
                            }}
                            min="0"
                            max="999999.99"
                          />
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                <Col xs="12" sm="6">
                  <Row form>
                    <Col>
                      <Label className="form-legend" htmlFor="local_pickup_catavento">
                        <strong>{util.t('LOCAL_PICKUP')}</strong>
                      </Label>
                    </Col>
                  </Row>

                  <Row form>
                    <Col lg="4">
                      <FormGroup>
                        <Label>Status</Label>
                        <StatusSwitch
                          // onChange={(status) => setStoreTenant({ ...storeTenant, local_pickup_catavento: status, local_pickup_min_days: 0 })}
                          onChange={(status) =>
                            setStore({ ...store, local_pickup: status, local_pickup_min_days: 0 })
                          }
                          checked={!!store.local_pickup}
                          name="local_pickup_catavento"
                        />
                      </FormGroup>
                    </Col>

                    <Col lg="4">
                      <FormGroup>
                        <Label htmlFor="local_pickup_min_days">
                          {util.t('WITHDRAWAL_DEADLINE')}
                        </Label>

                        <input
                          placeholder="0"
                          className="form-control"
                          type="number"
                          max="127"
                          step="1"
                          min="0"
                          value={storeTenant.local_pickup_min_days}
                          id="local_pickup_min_days"
                          name="local_pickup_min_days"
                          onChange={handleChangeStoreTenant}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>

                {id != 1 ? (
                  <>
                    <Col xs="12" sm="6">
                      <Row form>
                        <Col>
                          <Label className="form-legend" htmlFor="show_quantity_stock">
                            <strong>{util.t('SHOW_QUANTITY_IN_STOCK')}</strong>
                          </Label>
                        </Col>
                      </Row>

                      <Row form>
                        <Col lg="4">
                          <FormGroup>
                            <Label>Status</Label>
                            <StatusSwitch
                              onChange={(status) =>
                                setStore({ ...store, show_quantity_stock: status })
                              }
                              checked={!!store.show_quantity_stock}
                              name="show_quantity_stock"
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : null}
              </Row>
            </fieldset>

            <hr className="mt-5" />

            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <Button type="submit" color="primary" loading={saving}>
                    {util.t('SAVE')}
                  </Button>
                </footer>
              </Col>
            </Row>
          </>
        )}
      </form>
    </>
  );
}

export default memo(Form);
