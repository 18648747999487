import React, { memo } from 'react';

import { ListStates } from '~/assets/constants';

import util from '~/assets/util';

const states = Object.entries(ListStates);

interface StateSelectProps {
  emptyLabel?: string;
  [key: string]: any;
}

function StateSelect({ emptyLabel = 'ALL', ...props }: StateSelectProps) {
  return (
    <>
      <select className="form-control" {...props}>
        <option value="">{util.t(emptyLabel)}</option>
        {states.map((state) => {
          const id = state[0];
          const label = state[1];

          return (
            <option key={id} value={id}>
              {label}
            </option>
          );
        })}
      </select>
    </>
  );
}

export default memo(StateSelect);
