import React, { memo, useRef, useState } from 'react';

import { Modal as BModal, Row, Col, Label, Card, CardHeader, CardBody } from 'reactstrap';

import Button from '~/components/Button';
import ImageInput from '~/components/ImageInput';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface ModalProps {
  productId: any;
  currentUrl: any;
  isOpen: any;
  onClose: any;
  onSave: Function;
}

function Modal({ productId, currentUrl, isOpen, onClose, onSave }: ModalProps) {
  const [saving, setSaving] = useState(false);
  const [imageUrl, setImageUrl] = useState(currentUrl);

  const fileRef = useRef(null);

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    try {
      const form = new FormData();

      form.append('product_id', productId);

      const file = fileRef?.current;
      if (file) {
        form.append('cover', file);
      }

      const url = 'admin/products/cover';

      const config = { headers: { 'Content-Type': 'multipart/form-data' } };

      const res = await api.post(url, form, config);

      const data = res?.data;

      const thumbnail = data?.thumbnail;

      onSave(productId, thumbnail.url);
      onClose();

      notification.$s(util.t('IMAGE_ADDED_SUCCESSFULLY'));
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  const onChangeCover = (url, file) => {
    setImageUrl(url);

    fileRef.current = file;
  };

  return (
    <BModal
      backdrop={saving ? 'static' : true}
      autoFocus={false}
      isOpen={isOpen}
      size="lg"
      toggle={onClose}
      unmountOnClose>
      <Card>
        <CardHeader>{util.t('UPDATE_IMAGE')}</CardHeader>
        <CardBody>
          <div className="container-fluid p-3">
            <Row form>
              <Col md="12">
                <Label>{util.t('PRODUCT_COVER')}</Label>
                <ImageInput
                  className="box-product-cover"
                  id="product-cover"
                  name="product-cover"
                  src={imageUrl}
                  alt={util.t('PRODUCT_COVER')}
                  onChange={onChangeCover}
                />
              </Col>
            </Row>
            <hr />
            <Row>
              <Col>
                <footer className="d-flex justify-content-end">
                  <Button color="danger" className="mr-1" type="button" onClick={onClose}>
                    {util.t('CANCEL')}
                  </Button>

                  <Button color="primary" type="button" onClick={doSave} loading={saving}>
                    {util.t('SAVE')}
                  </Button>
                </footer>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
