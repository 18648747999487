import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Banners from './pages/Banners';
import Catalogues from './pages/Catalogues';
import Categories from './pages/Categories';
import Coupons from './pages/Coupons';
import Dashboard from './pages/Dashboard';
import General from './pages/General';
import Integration from './pages/Integration';
import Login from '~/pages/Login';
import Orders from './pages/Orders';
import PagSeguroConnect from './pages/PagSeguroConnect';
import Product from './pages/Products/new';
import Products from './pages/Products';
import Reports from './pages/Reports';
import Sections from './pages/Sections';
import Settings from './pages/Settings';
import Store from './pages/Stores/new';
import Stores from './pages/Stores';
import Users from './pages/Users';
import Policies from './pages/Policies';
import StyleEditor from './pages/StyleEditor';
import Hosts from './pages/Hosts';

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route path="/" element={<Dashboard />}>
          <Route path="/" index element={<Orders />} />
          <Route path="home" element={<Orders />} />
          <Route path="orders" element={<Orders />} />
          <Route path="reports" element={<Reports />} />
          <Route path="users" element={<Users />} />
          <Route path="banners" element={<Banners />} />
          <Route path="sections" element={<Sections />} />
          <Route path="general" element={<General />} />
          <Route path="settings" element={<Settings />} />
          <Route path="settings/pagseguro-connect/" element={<PagSeguroConnect />} />
          <Route path="coupons" element={<Coupons />} />
          <Route path="products" element={<Products />} />
          <Route path="products/new" element={<Product />} />
          <Route path="stores" element={<Stores />} />
          <Route path="stores/edit/:id" element={<Store />} />
          <Route path="stores/new" element={<Store />} />
          <Route path="integration" element={<Integration />} />
          <Route path="categories" element={<Categories />} />
          <Route path="products/edit/:id" element={<Product />} />
          <Route path="catalogues" element={<Catalogues />} />
          <Route path="policies" element={<Policies />} />
          <Route path="style-editor" element={<StyleEditor />} />
          <Route path="hosts" element={<Hosts />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
