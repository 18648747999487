import React, { memo, useCallback, useMemo, useState } from 'react';

import { Button, Spinner, Table } from 'reactstrap';

import { FaPen } from 'react-icons/fa';

import DeleteButton from '~/components/DeleteButton';

import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

interface HostsTableProps {
  hosts: any[];
  loading?: any;
  doUpdate: Function;
  onOpen: Function;
}

function HostsTable({ hosts, loading = false, doUpdate, onOpen }: HostsTableProps) {
  const [deleting, setDeleting] = useState([]);

  const doDelete = useCallback(
    async (id) => {
      try {
        setDeleting(deleting.concat([id]));

        const url = `admin/hosts/${id}`;

        await api.delete(url);

        doUpdate();
      } catch (e) {
        notification.$e(e);
      } finally {
        setDeleting(deleting.filter((deletingId) => deletingId != id));
      }
    },
    [deleting, doUpdate],
  );

  const list = useMemo(
    () =>
      hosts?.map((host) => (
        <tr className="w-100" key={host.tenant_host_id}>
          {<td>{host.host}</td>}
          <td>
            <div className="d-flex align-center justify-content-end">
              <Button
                className="mr-1"
                onClick={() => onOpen(host.tenant_host_id, host.host)}
                size="sm"
                outline
                color="info">
                <FaPen />
              </Button>

              <DeleteButton
                disabled={hosts.length == 1}
                onDelete={() => doDelete(host.tenant_host_id)}
                loading={deleting.indexOf(host.tenant_host_id) > -1}
                size="sm"
              />
            </div>
          </td>
        </tr>
      )),
    [hosts, deleting, doDelete, onOpen],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={2}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (hosts?.length) {
      return list;
    }

    return (
      <tr>
        <td className="text-center" colSpan={2}>
          <span>{util.t('NO_RECORDS_FOUND')}</span>
        </td>
      </tr>
    );
  };

  return (
    <>
      <Table className="table-fixed" hover responsive>
        <thead className="thead-light">
          <tr>
            <th>{util.t('DOMAIN')}</th>
            <th className="text-right">{util.t('ACTION')}</th>
          </tr>
        </thead>

        <tbody>{renderBody()}</tbody>
      </Table>
    </>
  );
}

export default memo(HostsTable);
