import React, { memo, useEffect, useState } from 'react';

import { Spinner } from 'reactstrap';
import util from '~/assets/util';

import api from '~/services/api';
import notification from '~/services/notification';

interface StoreSelectProps {
  withPublicStores?: any;
  className?: string;
  onChange: Function;
  showOptionAll?: any;
  [key: string]: any;
}

function StoreSelect({
  withPublicStores = false,
  className = '',
  onChange,
  showOptionAll = false,
  ...props
}: StoreSelectProps) {
  const [loading, setLoading] = useState(true);
  const [stores, setStores] = useState([]);

  const loadStores = async () => {
    try {
      const res = await api.get(
        `admin/tenants/stores?type=${withPublicStores ? 'all' : 'private'}`,
      );

      const { stores } = res?.data;

      setStores(stores);

      const defaultValue = stores.length && !showOptionAll ? stores[0].store_id : '';

      onChange(defaultValue);
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const renderOptions = () => {
    if (!stores.length) {
      return (
        <option selected disabled value="">
          {util.t('NO_STORES_FOUND')}
        </option>
      );
    }

    return (
      <>
        <option value="" disabled={!showOptionAll}>
          {showOptionAll ? util.t('ALL') : util.t('SELECT')}
        </option>
        {stores.map((store) => (
          <option key={store.store_id} value={store.store_id}>
            {store.company_name}
          </option>
        ))}
      </>
    );
  };

  useEffect(() => {
    loadStores();
  }, []);

  if (loading) {
    return <Spinner color="primary" className="d-block mx-auto mb-3" size="sm" />;
  }

  return (
    <select
      onChange={(ev) => onChange(ev.target.value)}
      {...props}
      className={`${className} form-control`}>
      {renderOptions()}
    </select>
  );
}

export default memo(StoreSelect);
