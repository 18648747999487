import React, { memo, useMemo } from 'react';

import { FormGroup, Row, Col, Label, Input, Alert } from 'reactstrap';

import util from '~/assets/util';

import { Chars, CouponCodeTypes } from '~/assets/constants';

interface GeneratorFieldsProps {
  coupon: any;
  setCoupon: Function;
  handleChange: any;
}

function GeneratorFields({ coupon, setCoupon, handleChange }: GeneratorFieldsProps) {
  const previewCodes = useMemo(() => {
    const length = coupon.code_length;

    let codes = [];

    for (let i = 0; i < 3; i++) {
      const example =
        coupon.code_type == CouponCodeTypes.NUMERIC
          ? util.randomString(length, Chars.NUMERIC)
          : util.randomString(length);

      codes.push(coupon.code + example);
    }

    return codes.map((code, index) => (
      <span key={index}>
        <strong>{code}</strong>...
      </span>
    ));
  }, [coupon.code, coupon.code_length, coupon.code_type]);

  return (
    <>
      <Row className="mb-2" form>
        <Col sm="2">
          <FormGroup>
            <Label htmlFor="qty">{util.t('QUANTITY')}</Label>
            <Input
              value={coupon.qty}
              onChange={handleChange}
              type="number"
              name="qty"
              id="qty"
              min={3}
              required
            />
          </FormGroup>
        </Col>

        <Col sm="2">
          <FormGroup>
            <Label htmlFor="code_length">{util.t('CODE_LENGTH')}</Label>
            <Input
              value={coupon.code_length}
              onChange={handleChange}
              type="number"
              name="code_length"
              id="code_length"
              min={1}
              max={32}
              required
            />
          </FormGroup>
        </Col>
      </Row>

      <Row form className="mb-2">
        <Col>
          <FormGroup check inline>
            <Label check htmlFor="numeric">
              <Input
                value={CouponCodeTypes.NUMERIC}
                checked={coupon.code_type == CouponCodeTypes.NUMERIC}
                onChange={() => setCoupon({ ...coupon, code_type: CouponCodeTypes.NUMERIC })}
                type="radio"
                name="code_type"
                id="numeric"
                required
              />

              {util.t('NUMERIC_CODE')}
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check htmlFor="alphanumeric">
              <Input
                value={CouponCodeTypes.ALPHANUMERIC}
                checked={coupon.code_type == CouponCodeTypes.ALPHANUMERIC}
                onChange={() => setCoupon({ ...coupon, code_type: CouponCodeTypes.ALPHANUMERIC })}
                type="radio"
                name="code_type"
                id="alphanumeric"
                required
              />

              {util.t('ALPHANUMERIC_CODE')}
            </Label>
          </FormGroup>
        </Col>
      </Row>

      <Row form>
        <Alert isOpen={!!coupon.code} color="info">
          <>
            {util.t('EXAMPLE')}:
            <br />
            {previewCodes}
          </>
        </Alert>
      </Row>
    </>
  );
}

export default memo(GeneratorFields);
