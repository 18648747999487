import React, { memo } from 'react';

import { Button } from 'reactstrap';

import { FaCopy } from 'react-icons/fa';

import util from '~/assets/util';

import notification from '~/services/notification';

interface BtnCopyProps {
  text: string;
  [key: string]: any;
}

function BtnCopy({ text, ...props }: BtnCopyProps) {
  const doCopy = async () => {
    await navigator.clipboard.writeText(text);

    notification.$s(util.t('COPIED_TO_CLIPBOARD'), null, false, true, 'top');
  };

  return (
    <Button size="sm" color="primary" type="button" onClick={doCopy} {...props}>
      <FaCopy className="mr-1" />
      {util.t('COPY')}
    </Button>
  );
}

export default memo(BtnCopy);
