import React, { memo, useEffect, useRef, useState } from 'react';

import { Col, FormGroup, Input, Label, Row } from 'reactstrap';

import Button from '~/components/Button';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface FormProps {
  onSave: Function;
  onClose: Function;
  id?: any;
  domain?: any;
}

function Form({ onSave, onClose, id = 0, domain = null }: FormProps) {
  const [saving, setSaving] = useState(false);

  const [host, setHost] = useState(domain);

  const inputDomainRef = useRef(null);

  const doSave = async (ev) => {
    ev.preventDefault();

    if (saving) {
      return false;
    }

    setSaving(true);

    let form = { host };

    try {
      id ? await api.put(`admin/hosts/${id}`, form) : await api.post('admin/hosts', form);

      notification.$s(util.t('DOMAIN_SAVED'));

      onSave();
      onClose();
    } catch (e) {
      notification.$e(e);

      console.error(e);
    } finally {
      setSaving(false);
    }
  };

  useEffect(() => {
    inputDomainRef?.current?.focus();
  }, []);

  return (
    <>
      <form onSubmit={doSave} className="container-fluid p-3">
        <>
          <fieldset>
            <Row form>
              <Col>
                <FormGroup>
                  <Label htmlFor="host">{util.t('DOMAIN')}</Label>
                  <Input
                    value={host}
                    onChange={(ev) => setHost(ev.target.value)}
                    type="text"
                    name="host"
                    id="host"
                    required
                    autoFocus
                    innerRef={inputDomainRef}
                  />
                </FormGroup>
              </Col>
            </Row>
          </fieldset>

          <hr />
          <Row>
            <Col>
              <footer className="d-flex justify-content-end">
                <Button color="danger" className="mr-1" type="button" onClick={onClose}>
                  {util.t('CANCEL')}
                </Button>

                <Button color="primary" type="submit" loading={saving} disabled={domain === host}>
                  {util.t('SAVE')}
                </Button>
              </footer>
            </Col>
          </Row>
        </>
      </form>
    </>
  );
}

export default memo(Form);
