import React, { memo, useState } from 'react';

import { Button, Spinner, UncontrolledTooltip } from 'reactstrap';

import { FaCheck } from 'react-icons/fa';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface MarkLocalPickupAvailableButtonProps {
  order: any;
  storeOrderId: any;
  onSave: Function;
  [key: string]: any;
}

function MarkLocalPickupAvailableButton({
  order,
  storeOrderId,
  onSave,
  ...props
}: MarkLocalPickupAvailableButtonProps) {
  const id = `btn-order-available-${order.store_order_id}`;

  const [loading, setLoading] = useState(false);

  const doSave = async () => {
    if (loading) {
      return false;
    }

    setLoading(true);

    const url = 'admin/orders/mark-local-pickup-available';

    try {
      const { data } = await api.post(url, {
        store_order_id: storeOrderId,
      });

      onSave(data.result);

      return notification.$s(util.t('ORDER_UPDATED_SUCCESSFULLY'));
    } catch (e) {
      console.error('MarkLocalPickupAvailableButton.doSave', e);

      notification.$e(e);
    } finally {
      setLoading(false);
    }
  };

  const renderIcon = () => {
    if (loading) {
      return <Spinner color="white" size="sm" />;
    }

    return (
      <>
        <FaCheck id={id} />

        <UncontrolledTooltip target={id} placement="top">
          {util.t('MARK_LOCAL_PICKUP_AVAILABLE')}
        </UncontrolledTooltip>
      </>
    );
  };

  return (
    <Button size="sm" outline color="success" type="button" onClick={doSave} {...props}>
      {renderIcon()}
    </Button>
  );
}

export default memo(MarkLocalPickupAvailableButton);
