import React, { memo } from 'react';

import { Badge, Table } from 'reactstrap';

import { format, parseISO } from 'date-fns';

import orderService from '~/services/order';

import util from '~/assets/util';

interface StatusHistoryTableProps {
  logs: any[];
}

function StatusHistoryTable({ logs }: StatusHistoryTableProps) {
  return (
    <Table striped bordered responsive>
      <thead>
        <th>{util.t('STATUS')}</th>
        <th>{util.t('DATE')}</th>
        <th>{util.t('ORIGIN')}</th>
        <th>{util.t('NOTE')}</th>
      </thead>

      <tbody>
        {logs.map((log) => {
          const date = format(parseISO(log.created_at), 'dd/MM/yyyy HH:mm');

          const status = orderService.getStatus(log.status);

          return (
            <tr key={log.order_status_log_id}>
              <td>
                <Badge color={status.color}>{status.label}</Badge>
              </td>

              <td>{date}</td>
              <td>{log.origin}</td>
              <td>
                <small>{log.note || '-'}</small>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

export default memo(StatusHistoryTable);
