import React, { memo } from 'react';

import debounce from 'lodash.debounce';

import AsyncSelect from 'react-select/async';

import api from '~/services/api';

import util from '~/assets/util';

interface SearchProductNameInputProps {
  setRef: any;
  [key: string]: any;
}

function SearchProductNameInput({ setRef, ...props }: SearchProductNameInputProps) {
  async function loadDataOptions(title) {
    try {
      const res = await api.get(`admin/products/search?q=${title}`);

      const { data } = res?.data;
      if (!data) {
        return [];
      }

      const options = data.map(
        (data) =>
          new Object({
            id: data.product_id,
            active: data.active,
            barcode: data.bar_code,
            companyName: data.company_name,
            hasStock: data.has_stock,
            name: data.name,
            outOfCatalogue: data.out_of_catalogue,
            pendingImage: !data.has_thumbnail,
            stock: data.stock,
            thumbnail: data.thumbnail,
          }),
      );

      return options.filter((row) =>
        row.name.trim().toLowerCase().includes(title.trim().toLowerCase()),
      );
    } catch (e) {
      console.error(e);
    }
  }

  const loadProducts = (inputValue) =>
    new Promise((resolve) => {
      resolve(loadDataOptions(inputValue));
    });

  const debouncedLoadProducts = debounce((inputValue, callback) => {
    inputValue.length > 2 && loadProducts(inputValue).then((options) => callback(options));
  }, 1000);

  return (
    <AsyncSelect
      ref={setRef}
      cacheOptions
      isClearable
      placeholder={util.t('TYPE_THE_PRODUCT_NAME')}
      loadingMessage={() => util.t('SEARCHING_THREE_DOTS')}
      noOptionsMessage={() => util.t('NO_OPTION')}
      loadOptions={debouncedLoadProducts}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.barcode}
      required
      {...props}
    />
  );
}

export default memo(SearchProductNameInput);
