import React, { memo, useCallback, useMemo } from 'react';

import { Spinner, Table } from 'reactstrap';
import { format, parseISO } from 'date-fns';

import DeleteButton from '../DeleteButton';

import api from '~/services/api';
import notification from '~/services/notification';

import util from '~/assets/util';

interface RulesTableProps {
  rules: any[];
  loading?: any;
  onOpen: Function;
  doUpdate: Function;
}

function RulesTable({ rules, loading = false, onOpen, doUpdate }: RulesTableProps) {
  const doDelete = useCallback(
    async (rule) => {
      try {
        const form = {
          rule,
        };

        const url = 'admin/products/rule/delete';

        await api.post(url, form);

        doUpdate();

        notification.$s(util.t('DISCOUNT_DELETED'));
      } catch (e) {
        notification.$e(e);
      }
    },
    [doUpdate],
  );

  const list = useMemo(
    () =>
      rules?.map((rule, i) => {
        return (
          <tr key={i}>
            <td>{rule.type == 'author' ? util.t('AUTHOR') : util.t('BRAND')}</td>
            <td>{rule.type == 'author' ? rule.author_name : rule.brand_name}</td>
            <td>{rule.percent}%</td>
            <td>{format(parseISO(rule.expires_at), 'dd/MM/yyyy')}</td>
            <td className="d-flex align-center justify-content-end">
              <DeleteButton onDelete={() => doDelete(rule)} size="sm" />
            </td>
          </tr>
        );
      }),
    [rules, doDelete],
  );

  const renderBody = () => {
    if (loading) {
      return (
        <tr>
          <th className="text-center w-100" colSpan={5}>
            <Spinner size="sm" color="primary" />
          </th>
        </tr>
      );
    }

    if (!rules?.length) {
      return (
        <tr>
          <td className="text-center" colSpan={5}>
            <span>{util.t('NO_RECORDS_FOUND')}</span>
          </td>
        </tr>
      );
    }

    return list;
  };

  return (
    <Table responsive hover>
      <thead className="thead-light">
        <tr>
          <th>{util.t('TYPE')}</th>
          <th>{`${util.t('BRAND')} / ${util.t('AUTHOR')}`}</th>
          <th>{util.t('DISCOUNT')} (%)</th>
          <th>{util.t('EXPIRATION_DATE')}</th>
          <th className="text-right">{util.t('ACTION')}</th>
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
}

export default memo(RulesTable);
