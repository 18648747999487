import React, { memo, useRef, useState } from 'react';

import {
  FormGroup,
  Label,
  Col,
  Row,
  Modal as BModal,
  Card,
  CardHeader,
  CardBody,
  Input,
} from 'reactstrap';

import Swal from 'sweetalert2';

import Button from '~/components/Button';
import StoreSelect from '~/components/StoreSelect';
import ExcelColumnSelect from '~/components/ExcelColumnSelect';

import api from '~/services/api';

import notification from '~/services/notification';

import util from '~/assets/util';

import { Columns } from './columns';

import './index.css';

interface ModalProps {
  toggle: Function;
  [key: string]: any;
}

function Modal({ toggle, ...props }: ModalProps) {
  const [loading, setLoading] = useState(false);

  const [form, setForm]: any = useState({ columns: Columns, store_id: 0 });

  const fileRef = useRef(null);

  const onChangeColumn = (ev, index) => {
    const value = ev.target.value;

    let columns = [...form.columns];

    if (value == 'AA') {
      columns[index].custom = 1;
    }

    columns[index].value = value;

    setForm({ ...form, columns });
  };

  const doImport = async (ev) => {
    ev.preventDefault();

    if (loading) {
      return;
    }

    setLoading(true);

    const formData = new FormData();

    const columns = form.columns;
    for (let i = 0; i < columns.length; i++) {
      const { id, value } = columns[i];

      value && formData.append(id, value);
    }

    const file = fileRef.current.files[0];

    formData.append('document', file);
    formData.append('store_id', form.store_id);

    const url = 'admin/products/import';

    try {
      const { data } = await api.post(url, formData);

      Swal.fire({
        icon: 'success',
        html: util.t('IMPORT_PRODUCTS_SUCCESS').replace('{total}', data.imported),
      });

      toggle();
    } catch (e) {
      showErrors(e);

      fileRef.current.value = null;

      console.error('ImportProductsButton.doSave', e);
    } finally {
      setLoading(false);
    }
  };

  const showErrors = (error) => {
    const data = error?.response?.data;

    if (!data?.line) {
      return notification.$e(error);
    }

    Swal.fire({
      icon: 'error',
      html: `${util.t('IMPORT_PRODUCTS_ERROR').replace('{line}', data.line)} <br/> ${util.t(
        data.message,
      )}`,
    });
  };

  return (
    <BModal size="lg" {...props}>
      <Card>
        <CardHeader>{util.t('IMPORT_PRODUCTS')}</CardHeader>
        <CardBody>
          <form onSubmit={doImport} className="container-fluid p-3">
            <Row form>
              <Col lg="6">
                <FormGroup>
                  <Label htmlFor="store-id">{util.t('DISTRIBUTION_CENTER')}</Label>

                  <StoreSelect
                    id="store-id"
                    name="store-id"
                    required
                    onChange={(id) => setForm({ ...form, store_id: id })}
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Row form>
              <Col>
                <FormGroup>
                  <Label htmlFor="products-file">{util.t('SPREADSHEET')}</Label>
                  <Input
                    type="file"
                    className="form-control-file"
                    placeholder={util.t('SELECT')}
                    name="products-file"
                    id="products-file"
                    innerRef={fileRef}
                    required
                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </FormGroup>
              </Col>
            </Row>

            <hr />

            <Label className="mb-3">{util.t('SELECT_SPREADSHEET_COLUMNS')}</Label>

            <Row className="spreadsheet-columns" form>
              {form.columns.map((column, index) => (
                <Col sm="6" md="4" lg="2" key={index}>
                  <FormGroup>
                    <Label htmlFor={column.id}>
                      {util.t(column.name)} {column.required && '(*)'}
                    </Label>
                    <ExcelColumnSelect
                      value={column.value}
                      custom={column.custom}
                      id={column.id}
                      key={index}
                      onChange={(ev) => onChangeColumn(ev, index)}
                      required={column.required}
                    />
                  </FormGroup>
                </Col>
              ))}
            </Row>

            <Row className="mt-3">
              <Col>
                <footer className="d-flex">
                  <Button loading={loading} type="submit" color="primary">
                    {util.t('IMPORT')}
                  </Button>
                </footer>
              </Col>
            </Row>
          </form>
        </CardBody>
      </Card>
    </BModal>
  );
}

export default memo(Modal);
